import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './Store/Store';
import MainThemeIndex from './Theme/MainThemeIndex';
import { SnackbarProvider } from 'notistack';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './i18n'; // Import the i18n configuration
import { LanguageProvider } from './LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <GoogleOAuthProvider clientId="355531949681-6i601q4db8iqp25kmpqtgjluorg9msc5.apps.googleusercontent.com">
    <MainThemeIndex>
      <Provider store={store}>
        <LanguageProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            autoHideDuration={3000}
          >
            <App />
          </SnackbarProvider>
        </LanguageProvider>
      </Provider>
    </MainThemeIndex>
  </GoogleOAuthProvider>
);

import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, Checkbox, FormControlLabel } from '@mui/material';
import AppleIMG from '../../../Assets/Images/Login/AppleIMG.png';
import GoogleIMG from '../../../Assets/Images/Login/GoogleIMG.png';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import Logo from '../Login/Logo';
import LayerComponent from '../../../Component/Common/LayerComponent';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Store/Store';
import GenerateDeviceId from '../../../Component/GenerateDeviceId';
import { useGoogleLogin } from '@react-oauth/google';
import { enqueueSnackbar } from 'notistack';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { socialMediaSignUp } from '../../../Store/action';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';


const useStyles = makeStyles(() => ({
    customTextField: {
        '& .MuiInputBase-input': {
            padding: '6px 12px !important',
            color: '#fff',
            '&::placeholder': {
                fontFamily: "Inter",
                fontWeight: 300,
                fontStyle: "italic"
            }

        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "10px",
            fontSize: "12px",
            '& fieldset': {
                borderColor: '#AFAFAF',
            },
            '&:hover fieldset': {
                borderColor: '#AFAFAF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#AFAFAF',
            },
        },
        // width: "90%",

    },
    LongTextField: {
        '& .MuiInputBase-input': {
            padding: '6px 12px !important',
            color: '#fff',
            '&::placeholder': {
                fontFamily: "Inter",
                fontWeight: 300,
                fontStyle: "italic"
            }
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "10px",
            fontSize: "12px",
            '& fieldset': {
                borderColor: '#AFAFAF',
            },
            '&:hover fieldset': {
                borderColor: '#AFAFAF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#AFAFAF',
            },
        },
        // width: "50%",

    },
    gradientButton: {
        width: '75px',
        height: '55px',
        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
        position: "relative",
        overflow: "hidden",
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        border: "1px solid #2D2D2D",
        borderRadius: "12px",
        boxShadow: "none",
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '-30px',
            left: '0',
            width: '100%',
            height: '100%',
            borderRadius: "12px",
            background: "radial-gradient(circle at top, #434343a8, transparent)",
            opacity: 0,
            transition: 'opacity 0.3s',
            boxShadow: "none",
        },
        '&:hover::before': {
            opacity: 1,
        },
        '&:hover': {
            boxShadow: "none", // Ensure no box shadow on hover
        },
    },
    mainContainer: {
        backgroundColor: '#0F0F0F',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: "column",
    },
    formContainer: {
        backgroundColor: '#1c1c1c',
        borderRadius: '8px',
        padding: '40px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        width: '90%',
        maxWidth: '1100px',
        color: '#fff',

    },
    separator: {
        borderBottom: '1px solid #FDFDFD',
        margin: '20px 0',
    },
    submitButton: {
        width: '100%',
        padding: '12px',
        borderRadius: '8px',
        border: '1px solid #2D2D2D',
        backgroundColor: '#1A1A1A',
        color: '#fff',
        textTransform: 'none',
        marginTop: '20px',
    },
    footerButton: {
        color: '#fff',
        textDecoration: 'underline',
        textTransform: 'none',
    },
    logo: {
        display: 'flex',
        marginBottom: '20px',
        justifyContent: "space-between",
        width: "412px",
        alignItems: "flex-start",
        flexDirection: "column",
    },
    pages: {
        display: 'flex',
        marginBottom: '20px',
        justifyContent: "space-between",
        alignItems: "flex-end",
    }
}));
interface EstablishmentData {
    logo: string;
    type: string;
    activity: string;
    address: string;
    company_name: string;
    tva_number: string;
}

interface SignUpData {
    f_name: string;
    l_name: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword?: string;
    lang: string;
    device_id: string;
    establishment: EstablishmentData;
}
interface SocialSignUpData {
    f_name: string;
    l_name: string;
    email: string;
    media_id: string;
    device_id: string;
    social_media_token: string;
    signup_type: string;
}
interface SubscriptionOneProps {
    setSignUpData: React.Dispatch<React.SetStateAction<Partial<SignUpData>>>;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}
interface AppleIdToken {
    iss: string; // Issuer Identifier for the Issuer of the response.
    aud: string; // Your client_id (the one you used to authenticate)
    exp: number; // Expiration time of the token in seconds since the UNIX epoch
    iat: number; // Time the token was issued
    sub: string; // The unique identifier for the user (user ID)
    email?: string; // The user’s email address
    email_verified?: string; // "true" if the email is verified
    is_private_email?: string; // "true" if the email is a private relay email
    auth_time?: number; // Time when the user last authenticated
    nonce?: string; // Optional nonce sent in the request
    nonce_supported?: boolean; // "true" if the nonce claim is supported
    given_name?: string; // User's first name (if available)
    family_name?: string; // User's last name (if available)
}
const SubscriptionOne = ({ setSignUpData, setStep }: SubscriptionOneProps) => {
    const { t, i18n } = useTranslation();

    const classes = useStyles();
    const deviceId = useSelector((state: RootState) => state.device.deviceId);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [fName, setFname] = useState('');
    const passwordRegx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*+-_=])[a-zA-Z0-9!@#$%&*+-_=]{8,16}$/;

    const formik = useFormik({
        initialValues: {
            f_name: '',
            l_name: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
            lang: 'English',
        },
        validate: (values) => {
            const errors: { [key: string]: string } = {};

            if (!values.f_name) {
                errors.f_name = 'Required';
            }

            if (!values.email) {
                errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }

            if (!values.password) {
                errors.password = 'Required';
            } else if (!passwordRegx.test(values.password)) {
                errors.password = 'Password must be 8-16 characters long, contain at least one digit, one uppercase letter, one lowercase letter, and one special character';
            }

            if (!values.confirmPassword) {
                errors.confirmPassword = 'Required';
            } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = 'Passwords do not match';
            }

            return errors;
        },
        onSubmit: (values) => {
            const { f_name, l_name, email, phone, password, lang } = values;
            const establishment: EstablishmentData = {
                logo: '',
                type: '',
                activity: '',
                address: '',
                company_name: '',
                tva_number: '',
            };
            const signUpDat: SignUpData = { f_name, l_name, email, phone, password, lang, device_id: deviceId, establishment };
            setSignUpData((prev) => ({ ...prev, ...signUpDat }));

            setStep((prevStep: number) => prevStep + 1);
        },
    });


    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log('Google token', tokenResponse.access_token);

            try {
                // Fetch user info using the token
                const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${tokenResponse.access_token}`,
                    },
                });
                console.log(userInfoResponse)
                const { given_name, family_name, email, sub } = userInfoResponse.data; // `sub` is the unique user ID (media_id)

                // Prepare the payload for your API
                const payload: SocialSignUpData = {
                    f_name: given_name,
                    l_name: family_name,
                    email: email,
                    media_id: sub, // unique Google user ID
                    device_id: deviceId, // assuming deviceId is available
                    social_media_token: tokenResponse.access_token, // the access token from Google
                    signup_type: "Google",
                };

                // Call your API to log in with the social media account
                const response = await dispatch(socialMediaSignUp(payload)).unwrap();
                enqueueSnackbar("Successfully login with Google", { variant: 'success' });
                navigate('/home');
            } catch (error: any) {
                console.error('Error during Google login or API call', error);
                enqueueSnackbar('Failed to login with Google', { variant: 'error' });
            }
        },
        onError: () => {
            console.error('Login Failed');
            enqueueSnackbar('Google login failed', { variant: 'error' });
        },
    });

    const appleLogin = async () => {
        try {
            // Initiate Apple sign-in
            const response = await window.AppleID.auth.signIn();
            console.log(response);

            if (response && response.authorization && response.authorization.id_token) {
                const idToken = response.authorization.id_token;

                // Decode the ID token to extract user information
                const decodedToken: AppleIdToken = jwtDecode(idToken);

                // Extract the email and sub (unique Apple user ID)
                const { email, sub } = decodedToken;

                // Get the user's name from the response if available (only available on first sign-in)
                const { givenName, familyName } = response.user?.name || {};

                console.log(decodedToken);
                console.log('First Name:', givenName);
                console.log('Last Name:', familyName);

                // Prepare the payload for your API
                const payload: SocialSignUpData = {
                    f_name: givenName || '',
                    l_name: familyName || '',
                    email: email || '',
                    media_id: sub, // unique Apple user ID
                    device_id: deviceId, // Replace with your actual device ID
                    social_media_token: idToken, // Token to be verified by the backend
                    signup_type: "Apple",
                };

                // Dispatch the sign-in action to your Redux store
                const apiResponse = await dispatch(socialMediaSignUp(payload)).unwrap();

                // Display success message and navigate to home
                enqueueSnackbar("Successfully login with Apple", { variant: 'success' });
                navigate('/home/main');
            } else {
                // Handle case where Apple sign-in fails
                enqueueSnackbar('Apple login failed', { variant: 'error' });
            }
        } catch (error: any) {
            // Handle errors
            console.error('Error during Apple login', error);
            enqueueSnackbar('Failed to login with Apple', { variant: 'error' });
        }
    };


    useEffect(() => {
        window.AppleID.auth.init({
            clientId: 'com.prometteur.allsports.service', // Replace with your actual Service ID
            scope: 'name email',
            redirectURI: 'https://allsports.prometteur.in/', // Replace with your actual redirect URI
            usePopup: true, // Set to true to use a popup instead of redirect
        });
    }, []);
    return (

        <>

            <Typography variant="subtitle1" align="center" sx={{ color: 'white', marginBottom: 3, fontFamily: "Inter", fontSize: "16px" }}>
                {t('RegisterWith')} :
            </Typography>
            <GenerateDeviceId />
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px' }}>
                <Button onClick={() => appleLogin()} variant="contained" className={classes.gradientButton}>
                    <LayerComponent />
                    <Box sx={{
                        position: 'relative',
                        zIndex: '1',
                        height: '50%'

                    }}>
                        <img src={AppleIMG} alt="Apple" style={{ height: '31px' }} />
                    </Box>
                </Button>
                <Button onClick={() => googleLogin()} variant="contained" className={classes.gradientButton}>
                    <LayerComponent />
                    <Box sx={{
                        position: 'relative',
                        zIndex: '1',
                        height: '50%'

                    }}>
                        <img src={GoogleIMG} alt="Google" style={{ height: '31px' }} />
                    </Box>
                </Button>
            </Box>

            <Box className={classes.separator}></Box>
            <form onSubmit={formik.handleSubmit}>
                <Typography variant="subtitle2" align="center" sx={{ color: '#FFFFFF', marginBottom: 3, fontFamily: "Inter", fontSize: "12px" }}>{t('Or')} :</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginBottom: '10px' }}>
                    <Box sx={{
                        display: 'flex', flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",

                    }}>
                        <Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontFamily: "Inter", fontSize: "12px", width: "62px" }}>{t('Name')}* :</Typography>
                        <TextField
                            id="f_name"
                            variant="outlined"
                            placeholder='Votre nom...'
                            className={classes.LongTextField}
                            {...formik.getFieldProps('f_name')}
                            error={formik.touched.f_name && Boolean(formik.errors.f_name)}
                            helperText={formik.touched.f_name && formik.errors.f_name}

                        />
                    </Box>
                    <Box sx={{
                        display: 'flex', flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: '5px'
                    }}>
                        <Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontFamily: "Inter", fontSize: "12px" }}>{t('FirstName')}* :</Typography>
                        <TextField
                            id="l_name"
                            variant="outlined"
                            placeholder='Votre prénom...'
                            className={classes.LongTextField}
                            {...formik.getFieldProps('l_name')}
                            error={formik.touched.l_name && Boolean(formik.errors.l_name)}
                            helperText={formik.touched.l_name && formik.errors.l_name}
                        />
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: '5px'
                }}>
                    <Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontFamily: "Inter", fontSize: "12px" }}>{t('Email')}* :</Typography>
                    <TextField
                        sx={{ flex: '1' }}
                        id="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        placeholder='exemple@email.com'
                        className={classes.customTextField}
                        {...formik.getFieldProps('email')}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </Box >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginBottom: '10px' }}>
                    <Box sx={{
                        display: 'flex', flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",

                    }}>
                        <Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontFamily: "Inter", fontSize: "12px", width: "174px" }}>{t('Password')}* :</Typography>
                        <TextField
                            id="password"
                            type="password"
                            variant="outlined"
                            placeholder='*********'
                            fullWidth
                            margin="normal"
                            className={classes.LongTextField}
                            {...formik.getFieldProps('password')}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex', flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "412px"
                    }}>
                        <Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontFamily: "Inter", fontSize: "12px", width: "174px" }}>{t('Confirmation')}* :</Typography>
                        <TextField
                            id="confirmPassword"
                            type="password"
                            placeholder='*********'
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            className={classes.LongTextField}
                            {...formik.getFieldProps('confirmPassword')}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        />
                    </Box>
                </Box>
                <Box className={classes.separator}></Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>


                    <Button type="submit" variant="contained" color="primary" sx={{
                        padding: '6px 24px',
                        borderRadius: '45px ',
                        border: "0.6px solid #2D2D2D",
                        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                        boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                        position: "relative",
                        overflow: "hidden",
                        '&:hover': {
                            background: '#FFFFFF',
                            color: '#000000',
                            cursor: 'pointer',
                        },
                    }}>
                        <LayerComponent />
                        <Box sx={{
                            position: 'relative',
                            zIndex: '1',
                        }}>
                            {t('Submit')}
                        </Box>
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default SubscriptionOne;

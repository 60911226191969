// src/Store/Slice/deviceSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DeviceState {
  deviceId: string;
}

const initialState: DeviceState = {
  deviceId: "",
};

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setDeviceId(state, action: PayloadAction<string>) {
      state.deviceId = action.payload;
    },
  },
});

export const { setDeviceId } = deviceSlice.actions;
export default deviceSlice.reducer;

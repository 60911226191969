// src/components/GenerateDeviceId.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';
import { setDeviceId } from '../Store/Slice/deviceSlice';
import { AppDispatch } from '../Store/Store';

const GenerateDeviceId: React.FC = () => {
    const [ipAddress, setIpAddress] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const fetchIpAddress = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            console.error('Error fetching IP address:', error);
            return null;
        }
    };

    const generateDeviceId = (ipAddress: string) => {
        const id = CryptoJS.SHA256(ipAddress).toString();
        return id;
    };

    useEffect(() => {
        const fetchAndGenerateDeviceId = async () => {
            const ip = await fetchIpAddress();
            if (ip) {
                setIpAddress(ip);
                const id = generateDeviceId(ip);
                dispatch(setDeviceId(id));
            }
        };

        fetchAndGenerateDeviceId();
    }, [dispatch]);

    return null; // No UI needed for this component
};

export default GenerateDeviceId;

import requests from "../Axios/Instance";

const MultimediaGetServices = {
  getMultimediaData: async (
    startDate: string,
    endDate: string,
    timeZone: string
  ) => {
    return requests.get(
      `/multiMedia?start_date=${startDate}&end_date=${endDate}&timezone=${timeZone}`
    );
  },
};

export default MultimediaGetServices;

import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from './Pages/AuthPages/Login/Login';
import MultiStepForm from './Pages/AuthPages/Subscription/MultiStepForm';
import Home from './Component/Home';
import MainContent from './Component/MainContent';
import CreateContent from './Component/CreateContent';
import Ligues from './Component/Content/Ligues';
import BackgroundImg from './Component/Content/BackgroundImg';
import MediathequePage from './Component/Mediathèque/MediathequePage';
import ProfilPage from './Component/Profil/ProfilPage';
import PaymentForm from './Component/Payment/PaymentForm';
import PayPalButton from './Pages/AuthPages/Subscription/PayPalButton';
import PayPalButtonComponent from './Pages/AuthPages/Subscription/PayPalButtonComponent';
import SubscriptionFinal from './Component/Common/SubscriptionFinal';

interface subscription {
  tag: string;
  price: number;
  validity: number;
  features: features[];
  img?: string;
  access_level: number;
}
interface features {
  title: string;
  description: string;
}
function App() {
  const [selectedBackground, setSelectedBackground] = useState<string>('');
  const [memberData, setMemberData] = useState<Partial<subscription>>({}); // Use Partial<subscription>
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/subscription" element={<MultiStepForm setMemberData={setMemberData} />} />
        <Route path="/home" element={<Home />}>
          <Route path="/home/main" element={<MainContent />} />
          <Route path="/home/create" element={<CreateContent />} />
          <Route path="/home/ligues" element={<Ligues />} />
          <Route path="/home/background" element={<BackgroundImg setSelectedBackground={setSelectedBackground} />} />
          <Route path="/home/mediathequePage" element={<MediathequePage />} />
          <Route path="/home/Profil" element={<ProfilPage />} />
          <Route path="/home/Profil/PayPalButton" element={<PayPalButtonComponent />} />
          <Route path="/home/subscriptionFinal" element={<SubscriptionFinal />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckImg1 from '../../../Assets/Images/Check.png';
import CheckImg2 from '../../../Assets/Images/Check1.png';
import LayerComponent from '../../../Component/Common/LayerComponent';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { customerSignUp, getFileUploadData, postSubscriptionData } from '../../../Store/action';
import { unwrapResult } from '@reduxjs/toolkit';
import { AppDispatch } from '../../../Store/Store';
// import { useSnackbar } from '../../../Component/Common/SnackbarProvider';
import { enqueueSnackbar } from 'notistack';
import SubscriptionPlansServices from '../../../Services/SubscriptionPlansServices';
import PayPalButton from './PayPalButton';
const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px',
        // marginTop: '20px',
        justifyContent: 'center',
    },
    card: {
        width: '258px',
        maxWidth: '400px',
        border: '0.8px solid #303030',
        borderRadius: '11px',
        height: '350px',
        padding: '20px',
        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
        color: '#FFFFFF', // Set text color to white
        position: "relative",
        overflow: "hidden"
    },
    featureItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '14px',
        marginBottom: '30px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
        position: 'absolute',
        bottom: '25px',
        left: '50%',
        transform: 'translate(-50%)'
    },
    button: {
        padding: '8px 24px',
        borderRadius: '45px',
        border: '1px solid #363636',
        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
        boxShadow: "none",
    },
    premiumButton: {
        border: '1px solid #363636',
        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
    },
    separator: {
        borderBottom: '1px solid #FFFFFF',
        margin: '18px 0',
    },
}));

interface EstablishmentData {
    logo: string;
    type: string;
    activity: string;
    address: string;
    company_name: string;
    tva_number: string;
}

interface SignUpData {
    f_name: string;
    l_name: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword?: string;
    lang: string;
    device_id: string;
    establishment: EstablishmentData;
}

interface SignUpProps {
    signUpData: Partial<SignUpData>;
    logoImg: File | null;
    setMemberData: React.Dispatch<React.SetStateAction<Partial<subscription>>>;

}

interface FileUpload {
    data: string[];
}
interface subscription {
    tag: string;
    price: number;
    validity: number;
    features: features[];
    img?: string;
    access_level: number;
}
interface features {
    title: string;
    description: string;
}
interface subscriptionState {
    data: subscription[];

}

const SubscriptionThree = ({ signUpData, logoImg, setMemberData }: SignUpProps) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const [subscriptionPlans, setSubscriptionPlans] = useState<subscriptionState>({ data: [] });
    const [loading, setLoading] = useState(true);
    const [showPayPal, setShowPayPal] = useState(true);
    useEffect(() => {
        SubscriptionPlansServices.getSubscriptionPlanData()
            .then(res => {
                setSubscriptionPlans(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);
    console.log(subscriptionPlans.data)
    const isCompleteSignUpData = (data: Partial<SignUpData>): data is SignUpData => {
        return data.f_name !== undefined && data.l_name !== undefined && data.email !== undefined &&
            data.phone !== undefined && data.password !== undefined && data.lang !== undefined &&
            data.device_id !== undefined && data.establishment !== undefined;
    };
    const handleFreeClick = async (membership: subscription) => {
        console.log(membership)
        if (logoImg) {
            const formData = new FormData();
            formData.append('folderName', 'logo');
            formData.append('files', logoImg, logoImg.name);

            try {
                const response: FileUpload = unwrapResult(await dispatch(getFileUploadData(formData)));
                const imageUrl = response.data[0];
                console.log(imageUrl);
            } catch (err) {
                if (err instanceof Error) {
                    console.error('Error:', err.message);
                    enqueueSnackbar(err.message, { variant: 'error' });
                } else {
                    console.error('An unexpected error occurred');
                    enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
                }
                // console.error('Error uploading file:', error);
            }
        } else {
            console.error('Logo image is not provided');
        }

        if (isCompleteSignUpData(signUpData)) {
            try {
                const res = await dispatch(customerSignUp(signUpData)).unwrap();
                console.log(res);
                const userId = res.data.id;
                const subscriptionData = {
                    "user_id": userId,
                    "tag": membership.tag,
                    "price": membership.price,
                    "validity": membership.validity,
                    "access_level": membership.access_level,
                    "features": membership.features,
                };

                try {
                    await dispatch(postSubscriptionData(subscriptionData)).unwrap();
                    enqueueSnackbar('Signup successfully', { variant: 'success' });
                    navigate('/home');
                } catch (err) {
                    console.log(err)
                    if (err instanceof Error) {
                        console.error('Error:', err.message);
                        enqueueSnackbar(err.message, { variant: 'error' });
                    } else {
                        console.error('An unexpected error occurred');
                        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
                    }
                }
            } catch (error: any) {
                console.log(error.message);
                enqueueSnackbar(error.message, { variant: 'error' });
            }

        } else {
            console.error('SignUpData is incomplete');
        }

        localStorage.removeItem('selectedEvents');
    };

    const handlePremiumClick = async (membership: subscription) => {
        setMemberData(membership);


        if (logoImg) {
            const formData = new FormData();
            formData.append('folderName', 'logo');
            formData.append('files', logoImg, logoImg.name);

            try {
                const response: FileUpload = unwrapResult(await dispatch(getFileUploadData(formData)));
                const imageUrl = response.data[0];
                console.log(imageUrl);
            } catch (error: unknown) {
                if (error instanceof Error) {
                    console.log(error.message);
                    enqueueSnackbar(error.message, { variant: 'error' });
                } else {
                    console.log('An unknown error occurred');
                    enqueueSnackbar('An unknown error occurred', { variant: 'error' });
                }
            }
        } else {
            console.error('Logo image is not provided');
        }

        if (isCompleteSignUpData(signUpData)) {
            try {

                const res = await dispatch(customerSignUp(signUpData)).unwrap();
                const userId = res.data.id;

                navigate('/home/Profil/PayPalButton', { state: { subscription: membership, userId } });

                // Show PayPal button
                // setShowPayPal(true);
                // const handlePaymentSuccess = async (details: any) => {
                //     try {
                //         await dispatch(postSubscriptionData(subscriptionData)).unwrap();
                //         enqueueSnackbar('Sign up successful', { variant: 'success' });
                //         navigate('/home');
                //     } catch (error: unknown) {
                //         if (error instanceof Error) {
                //             console.log(error.message);
                //             enqueueSnackbar(error.message, { variant: 'error' });
                //         } else {
                //             console.log('An unknown error occurred');
                //             enqueueSnackbar('An unknown error occurred', { variant: 'error' });
                //         }
                //     }
                // };

                // const handlePaymentError = (error: any) => {
                //     console.log(error);
                //     enqueueSnackbar('Payment failed', { variant: 'error' });
                // };
                // console.log(showPayPal)
                // if (showPayPal) {
                //     console.log("show Paypal button")
                //     return (
                //         <PayPalButton
                //             amount={membership.price.toString()}
                //             onSuccess={handlePaymentSuccess}
                //             onError={handlePaymentError}
                //         />
                //     );
                // }

            } catch (error: any) {
                console.log(error.message);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        } else {
            console.error('SignUpData is incomplete');
        }

        localStorage.removeItem('selectedEvents');
    };

    return (
        <Box sx={{
            py: 4, px: 3, display: 'flex',
            flexDirection: 'column',
            gap: '50px'
        }}>
            <Box className={classes.separator} sx={{ marginBottom: "-35px" }}></Box>
            <Box className={classes.container}>
                {subscriptionPlans && subscriptionPlans.data.map((membership, index) => (
                    <Box key={index} className={classes.card}>
                        <LayerComponent />
                        <Box
                            sx={{
                                zIndex: '1',
                            }}>
                            <Box sx={{ mb: 2 }}>
                                {membership.features.map((feature, idx) => (
                                    <Box key={idx} className={classes.featureItem}>
                                        <img src={membership?.img || (membership.tag === 'Premium' ? CheckImg2 : CheckImg1)} alt={`Check ${idx + 1}`} height="15px" width="19px" />
                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <Typography variant="body1" sx={{ color: "#FFFFFF", fontFamily: "Inter" }}>{feature.title}</Typography>
                                            <Typography variant="body2" sx={{ color: "#FFFFFF", fontFamily: "Inter" }}>{feature.description}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            <Typography variant="body1" sx={{ color: "#FFFFFF", fontFamily: "Inter" }}>$ {membership.price}</Typography>
                            <Box className={classes.buttonContainer}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        padding: '6px 24px',
                                        borderRadius: '45px ',
                                        border: "0.6px solid #2D2D2D",
                                        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                                        boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                                        position: "relative",
                                        overflow: "hidden",
                                        '&:hover': {
                                            background: '#FFFFFF',
                                            color: '#000000',
                                            cursor: 'pointer',
                                        },
                                    }}
                                    onClick={membership.tag === 'Free' ? () => handleFreeClick(membership) : () => handlePremiumClick(membership)}
                                >
                                    <LayerComponent />
                                    <Box sx={{
                                        position: 'relative',
                                        zIndex: 1,
                                        ...(membership.tag === 'Premium' && {
                                            background: "linear-gradient(180deg, #F9E785 0%, #A87A20 100%)",
                                            backgroundClip: "text",
                                            textFillColor: "transparent",
                                            boxShadow: "none",
                                        }),
                                    }}>
                                        {membership.tag}
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box className={classes.separator}></Box>
        </Box>
    );
};

export default SubscriptionThree;

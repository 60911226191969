import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../Pages/AuthPages/Login/Logo';
import Galary_Icon from "../Assets/Images/SideBarIcons/Galary_Icon.png";
import Home_icon from "../Assets/Images/SideBarIcons/Home_icon.png";
import Profile_Icon from "../Assets/Images/SideBarIcons/Profile_Icon.png";
import create_Icon from "../Assets/Images/SideBarIcons/create_Icon.png";
import { useTranslation } from 'react-i18next';
import AuthServices from '../Services/AuthServices';
import { enqueueSnackbar } from 'notistack';

const Sidebar: React.FC = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [userLogo, setUserLogo] = useState<string>('');

    useEffect(() => {
        console.log(location.pathname)
        if (location.pathname === '/home') {
            navigate('/home/main');
            setActiveItem('/home/main');

        } else {
            if (location.pathname != '/home/ligues' && location.pathname != '/home/background') {
                localStorage.removeItem('selectedEvents');
            }
            setActiveItem(location.pathname);
        }
    }, [location, navigate]);

    const handleListItemClick = (path: string) => {
        setActiveItem(path);
        navigate(path);
    };

    const getIconStyle = (path: string | string[]) => {
        // Check if activeItem is not null and is either in the array or matches the string
        const isActive = activeItem && (
            Array.isArray(path)
                ? path.includes(activeItem)
                : path === activeItem
        );

        return {
            height: '24px',
            width: '24px',
            opacity: isActive ? '100%' : '35%',
        };
    };



    const getIconStyleProfile = (path: string) => ({
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        border: '1px solid #FFFFFF',
        padding: '2px',
        objectFit: 'contain' as 'contain',
        opacity: activeItem === path ? '100%' : '35%',
    });
    const handleLogout = () => {
        AuthServices.customerLogOut()
            .then(res => {
                enqueueSnackbar('Successfully Logout', { variant: 'success' });
                // localStorage.clear();
                // localStorage.removeItem('authToken');  // Example of an auth token
                navigate("/");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    useEffect(() => {
        // Function to update logo based on localStorage
        const updateLogo = () => {
            const userData = localStorage.getItem("user_profile");
            const logo = userData ? JSON.parse(userData)?.establishment?.logo : '';
            setUserLogo(logo);
        };

        // Initial load
        updateLogo();
        const timer = setTimeout(() => {
            updateLogo();
        }, 1000);

        // Listener for storage event
        window.addEventListener("storage", updateLogo);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener("storage", updateLogo);
            clearTimeout(timer);
        };
    }, []);

    console.log(userLogo)
    return (
        <Drawer variant="permanent" anchor="left" sx={{ '& .MuiDrawer-paper': { backgroundColor: '#000000', color: 'white', width: '240px' } }}>
            <Box sx={{ textAlign: "center", marginBottom: "20px", marginTop: "20px", cursor: "pointer" }} onClick={() => handleListItemClick('/home/main')}>
                <Logo />
            </Box>
            <List>
                <ListItem
                    button
                    onClick={() => handleListItemClick('/home/main')}
                    sx={{ color: activeItem === '/home/main' ? '#FFFFFF' : '#808080' }}
                >
                    <ListItemIcon sx={{ color: activeItem === '/home/main' ? '#FFFFFF' : '#808080', minWidth: '40px' }}>
                        <img src={Home_icon} alt="" style={getIconStyle('/home/main')} />
                    </ListItemIcon>
                    <ListItemText primary={t('welcome')} />
                </ListItem>
                <ListItem
                    button
                    onClick={() => handleListItemClick('/home/create')}
                    sx={{ color: activeItem === '/home/create' ? '#FFFFFF' : activeItem === '/home/ligues' ? '#FFFFFF' : activeItem === '/home/background' ? '#FFFFFF' : '#808080' }}
                >
                    <ListItemIcon sx={{ color: activeItem === '/home/create' ? '#FFFFFF' : '#808080', minWidth: '40px' }}>
                        <img src={create_Icon} alt="" style={getIconStyle(['/home/create', '/home/ligues', '/home/background'])} />
                    </ListItemIcon>
                    <ListItemText primary={t('Create')} />
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        handleListItemClick('/home/mediathequePage');
                    }
                    }
                    sx={{ color: activeItem === '/home/mediathequePage' ? '#FFFFFF' : '#808080' }}
                >
                    <ListItemIcon sx={{ color: activeItem === '/home/mediathequePage' ? '#FFFFFF' : '#808080', minWidth: '40px' }}>
                        <img src={Galary_Icon} alt="" style={getIconStyle('/home/mediathequePage')} />
                    </ListItemIcon>
                    <ListItemText primary={t('MediaLibrary')} />
                </ListItem>
                <ListItem
                    button
                    onClick={() => handleListItemClick('/home/Profil')}
                    sx={{ color: activeItem === '/home/Profil' ? '#FFFFFF' : '#808080' }}
                >
                    <ListItemIcon sx={{ color: activeItem === '/home/Profil' ? '#FFFFFF' : '#808080', minWidth: '40px' }}>
                        <img src={userLogo !== '' ? `${process.env.REACT_APP_FILE_URL}/logo/${userLogo}` : Profile_Icon} alt="" style={getIconStyleProfile('/home/Profil')} />
                    </ListItemIcon>
                    <ListItemText primary={t('Profile')} />
                </ListItem>
            </List>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Button type="submit" variant="contained" color="primary" sx={{

                    padding: '6px 43px',
                    borderRadius: '30px ',
                    border: "1px solid #8F8F8F",
                    background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                    boxShadow: "none",
                    position: "absolute",
                    bottom: "10px"
                }} onClick={handleLogout}>
                    {t('Logout')}
                </Button>
            </Box>
        </Drawer>
    );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, IconButton, Grid, Button } from '@mui/material';
import { Download, Info } from '@mui/icons-material';
import Team1 from '../../Assets/Images/Games/game1.png'
import Team2 from '../../Assets/Images/Games/game2.png'
import DL from '../../Assets/Images/DL.png'
import MultimediaGetServices from '../../Services/MultimediaGetServices';
import { useTranslation } from 'react-i18next';


interface Record {
  start: string;
  [key: string]: any; // Adjust this according to the structure of your records
}

interface GroupedData {
  _id: string;
  records: any;
}
const MediathequePage = () => {

  const [multimediaData, setMultimediaData] = useState<GroupedData[]>([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const timeZone: string = localStorage.getItem('timeZone') || '';
  const startDate = "2024-05-15T18:30:00Z";
  // const endDate = "2024-08-15T18:30:00Z";
  // const startDate = new Date().toISOString();
  const endDate = new Date(new Date().setDate(new Date().getDate() + 90)).toISOString();
  const limit = 50;
  const pageNo = 1;

  useEffect(() => {
    MultimediaGetServices.getMultimediaData(startDate, endDate, timeZone)
      .then(res => {
        const data = res.data;
        setMultimediaData(data.data);
        console.log(data)
        // const groupedData = groupByDate(data);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  // const groupByDate = (data: { data: Record[] }): GroupedData[] => {
  //   const grouped: { [key: string]: Record[] } = {};

  //   data.data.forEach(record => {
  //     const startDate = new Date(record.start).toISOString().split('T')[0];

  //     if (!grouped[startDate]) {
  //       grouped[startDate] = [];
  //     }

  //     grouped[startDate].push(record);
  //   });

  //   const sortedGroupedData = Object.keys(grouped)
  //     .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
  //     .map(date => ({
  //       startDate: date,
  //       records: grouped[date]
  //     }));

  //   return sortedGroupedData;
  // };

  console.log(multimediaData);

  const handleDownloadStory = async (url: string) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = url.split('/').pop() || 'download';
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  const handleDownloadSquare = async (url: string) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = url.split('/').pop() || 'download';
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const optionsDay: Intl.DateTimeFormatOptions = { weekday: 'long' };
    const optionsDate: Intl.DateTimeFormatOptions = { day: 'numeric' };
    const optionsMonth: Intl.DateTimeFormatOptions = { month: 'long' };

    const formattedDay = date.toLocaleDateString('en-US', optionsDay);
    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedMonth = date.toLocaleDateString('en-US', optionsMonth);

    return {
      day: formattedDay,
      date: formattedDate,
      month: formattedMonth,
    };
  };
  const formatDateTime = (dateString: string, timeZone: string) => {
    const date = new Date(dateString);

    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false, // Use 24-hour format
      timeZone
    };

    const formattedTime = new Intl.DateTimeFormat('en-US', optionsTime).format(date);

    return formattedTime;
  };


  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, ml: '240px', backgroundColor: '#191919', color: 'white' }}>
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <Typography variant="h3" sx={{ fontFamily: "BlairITC", LineHeight: "44px" }}>{t('MEDIA')}</Typography>
        <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", }}>{t('DOWNLOADYOURCONTENT')}</Typography>
      </Box>
      {multimediaData == null ? (
        <Typography>Loading...</Typography>
      ) : multimediaData.length === 0 ? (
        <Typography>No data found</Typography>
      ) : (
        multimediaData.map((matchDay, index) => {
          const { day, date, month } = formatDate(matchDay?._id);

          return <Box key={index} sx={{
            marginBottom: 4, display: 'flex', backgroundColor: "#111111",
            padding: "20px", borderRadius: "15px", gap: "40px",
          }}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100px" }}>
              <Typography variant="h4" sx={{ marginBottom: "25px", lineHeight: "1px", fontFamily: "Wedding Gothic ATF", marginTop: "10px", }} >
                {day}
              </Typography>
              <Typography sx={{ marginBottom: "23px", lineHeight: "1px", fontFamily: "Wedding Gothic ATF", fontSize: "44px" }}>
                {date}
              </Typography>
              <Typography variant="h4" sx={{ lineHeight: "1px", fontFamily: "Wedding Gothic ATF", }}>
                {month}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {matchDay.records.map((event: Record, eventIndex: number) => {
                console.log(event.competitor1.profile_img)
                const startTime = formatDateTime(event.start, timeZone);
                const endTime = formatDateTime(event.end, timeZone);
                return <Grid item xs={12} key={eventIndex}>
                  <Card sx={{ background: " radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)", color: 'white', height: "62px", borderRadius: '12px ', boxShadow: "0px 4px 4px 0px #00000040", border: '0.5px solid #2D2D2D' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", padding: "0px 13px 0px 13px", boxShadow: "0px 2px 3px 0px #0B0B0B63 inset" }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: "20px" }}>
                        {/* <Button variant="contained" color="primary" sx={{ marginRight: 1 }}>Story</Button> */}
                        <Box sx={{
                          display: 'flex', alignItems: 'center', padding: "0 15px 0px 15px", background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)", borderRadius: '6px ',
                          border: '1px solid #2D2D2D', cursor: 'pointer'
                        }} onClick={() => handleDownloadStory(`${process.env.REACT_APP_FILE_URL}/banner/${event?.story_img}`)}>
                          <IconButton color="primary">
                            <img src={DL} alt="IconImg" style={{ width: "18px", height: "16px" }} />
                          </IconButton>
                          <Typography variant="body1" sx={{ color: "#FFFFFF", fontFamily: "Inter" }}>{t('Story')}</Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex', alignItems: 'center', padding: "0 15px 0px 15px", background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)", borderRadius: '6px ',
                          border: '1px solid #2D2D2D',
                          cursor: 'pointer'
                        }} onClick={() => handleDownloadSquare(`${process.env.REACT_APP_FILE_URL}/banner/${event?.web_img}`)}>
                          <IconButton color="primary">
                            <img src={DL} alt="IconImg" style={{ width: "18px", height: "16px" }} />
                          </IconButton>
                          <Typography variant="body1" sx={{ color: "#FFFFFF", fontFamily: "Inter" }}>{t('Square')}</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: "32%" }}>
                        <Box sx={{ flexGrow: 1, width: "80%", display: "flex", flexDirection: "column" }}>
                          <Typography variant="body1" sx={{ color: "#FFFFFF", fontFamily: "Inter", fontSize: "11px" }}>{event.competitor1.name}  </Typography>
                          <Typography variant="body1" sx={{ color: "#FFFFFF", fontFamily: "Inter", fontSize: "11px" }}>   {event.competitor2.name}</Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography variant="caption" sx={{ color: "#4D4D4D", fontSize: '12px' }}>{startTime} - </Typography>
                          <Typography variant="caption" sx={{ color: "#4D4D4D", fontSize: '12px' }}>{endTime}</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ borderBottom: 'none', display: "flex", width: "40%", justifyContent: " space-between" }}>
                        <img src={event.competitor1.profile_img} alt={`${event.competitor1.name} logo`} style={{ width: "198px", height: "62px", marginRight: 10, objectFit: 'cover' }} />
                        <img src={event.competitor2.profile_img} alt={`${event.competitor2.name} logo`} style={{ width: "198px", height: "62px", objectFit: 'cover' }} />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              })}
            </Grid>
          </Box>
        }))}
    </Box>
  );
};

export default MediathequePage;

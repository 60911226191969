// src/redux/Slice/selectedSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedState {
  sportKey: string;
  leagueKey: string;
  leagueId: string;
}

const initialState: SelectedState = {
  sportKey: "",
  leagueKey: "",
  leagueId: "",
};

const selectedLeagueSlice = createSlice({
  name: "selected",
  initialState,
  reducers: {
    setSelectedSport(state, action: PayloadAction<string>) {
      state.sportKey = action.payload;
    },
    setSelectedLeague(state, action: PayloadAction<string>) {
      state.leagueKey = action.payload;
    },
    setSelectedLeagueId(state, action: PayloadAction<string>) {
      state.leagueId = action.payload;
    },
    resetSelected(state) {
      state.sportKey = "";
      state.leagueKey = "";
      state.leagueId = "";
    },
  },
});

export const {
  setSelectedSport,
  setSelectedLeague,
  resetSelected,
  setSelectedLeagueId,
} = selectedLeagueSlice.actions;
export default selectedLeagueSlice.reducer;

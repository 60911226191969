import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_SECRET = process.env.REACT_APP_API_SECRET;

const instance = axios.create({
  baseURL: BaseUrl,
  headers: {
    api_key: API_KEY,
    api_secrete: API_SECRET,
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user)?.data?.auth_token : null;

    console.log(token);

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      window.location.replace(`${process.env.PUBLIC_URL}/`);
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default instance;

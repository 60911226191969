// src/LanguageContext.tsx
import React, { createContext, useState, useEffect, ReactNode, FC } from 'react';
import i18n from './i18n';

// Define the shape of the context value
interface LanguageContextProps {
    language: string;
    changeLanguage: (lang: string) => void;
}

// Create the context with a default value
export const LanguageContext = createContext<LanguageContextProps>({
    language: 'en',
    changeLanguage: () => { },
});

interface LanguageProviderProps {
    children: ReactNode;
}

// Create the LanguageProvider component
export const LanguageProvider: FC<LanguageProviderProps> = ({ children }) => {
    const [language, setLanguage] = useState<string>(i18n.language);

    const changeLanguage = async (lang: string) => {
        // try {
        //     await i18n.changeLanguage(lang); // Update i18n language
        //     setLanguage(lang); // Update state
        //     localStorage.setItem('user_profile', JSON.stringify({ lang })); // Update localStorage
        // } catch (error) {
        //     console.error('Failed to change language:', error);
        // }
    };

    useEffect(() => {
        const storedLang = localStorage.getItem("user_profile");
        if (storedLang) {
            const lang = JSON.parse(storedLang)?.lang || 'en';
            changeLanguage(lang);
        }
    }, []); // Empty dependency array to run only on mount

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#0F0F0F",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  1000: "#B0B0B0",
  1100: "rgba(42, 63, 19, 0.04)",
  1200: "#FCFFF9",
};

const PRIMARY = {
  lighter: "#79846e",
  light: "#4d5b3d",
  main: "#20320D",
  // dark: "#1a280a",
  darker: "#131e08",
  forgot: "#0034EB",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "rgba(241, 78, 24, 0.10);",
  light: "#655342",
  main: "#3f2813",
  dark: "#32200f",
  darker: "#26180b",
  contrastText: "#fff",
  black:"var(--Foundation-black-tints-black-tints-900, #000)"
  
};

const INFO = {
  lighter: "#74c7d4",
  light: "#45b5c6",
  main: "#17a2b8",
  dark: "#128293",
  darker: "#0e616e",
  contrastText: GREY[800],
};

const SUCCESS = {
  lighter: "#7eca8f",
  light: "#53b96a",
  main: "#28a745",
  dark: "#208637",
  darker: "#186429",
  contrastText: "#fff",
};

const WARNING = {
  lighter: "#ffda6a",
  light: "#ffcd39",
  main: "#ffc107",
  dark: "#cc9a06",
  darker: "#997404",
  contrastText: GREY[800],
};

const CUSTOMIZE = {
  lighter: "#feffd1",
  light: "#fdffc2",
  main: "#fdffb3",
  dark: "#cacc8f",
  darker: "#98996b",
  contrastText: GREY[800],
};

const CUSTOMIZESECONDARY = {
  lighter: "#EAE7FF",
  light: "#f47146",
  main: "#130094",
  dark: "#c13e13",
  darker: "#912f0e",
  contrastText: GREY[800],
};
const CUSTOMIZETERTARY = {
  lighter: "#EBFEF8",
  light: "#62f4c7",
  main: "#2AAB83",
  dark: "#2fc194",
  darker: "#23916f",
  contrastText: GREY[800],
};
const CUSTOMIZERED = {
  lighter: "rgba(241, 78, 24, 0.20)",
  light: "#9033f9",
  main: "#F11818",
  dark: "#5d00c6",
  darker: "#460095",
  contrastText: GREY[800],
};
const CUSTOMIZEORANGE = {
  lighter: "#FFF2E7",
  light: "#9033f9",
  main: "#E66E09",
  dark: "#5d00c6",
  darker: "#460095",
  contrastText: GREY[800],
};
const CUSTOMIZEBLUE = {
  lighter: "#E7F0FF",
  light: "#9033f9",
  main: "#0D6EFD",
  dark: "#5d00c6",
  darker: "#460095",
  contrastText: GREY[800],
};
const CUSTOMIZEDARKBLUE = {
  lighter: " #F1E6FE",
  light: "#9033f9",
  main: "#7400F8",
  dark: "#5d00c6",
  darker: "#460095",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  customizePrimary: CUSTOMIZE,
  customizeSecondary: CUSTOMIZESECONDARY,
  customizetertary: CUSTOMIZETERTARY,
  customizedBlue: CUSTOMIZEBLUE,
  customizedDarkBlue: CUSTOMIZEDARKBLUE,
  customizedRed:CUSTOMIZERED,
  customizedOrange:CUSTOMIZEORANGE,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: "#0F0F0F",
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;

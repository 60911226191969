import requests from "../Axios/Instance";

interface MultimediaParams {
  competitor1: competitorData;
  competitor2: competitorData;
  start: string;
  end: string;
  story_img: string;
  web_img: string;
}
interface competitorData {
  name: string;
  profile_img: string;
}
const MultimediaServices = {
  postMultimediaData: async (body: MultimediaParams) => {
    return requests.post("/multiMedia", body);
  },
};

export default MultimediaServices;

// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Function to get the stored language from localStorage
export const getStoredLanguage = () => {
    const userData = localStorage.getItem("user_profile");
    return userData ? JSON.parse(userData)?.lang : 'en'; // Default to 'en' if no language is stored
};
console.log(getStoredLanguage())
// Initialize i18n
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: getStoredLanguage(), // Set the initial language based on localStorage
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false, // React already escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
    });

// Export i18n instance
export default i18n;

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PayPalButton from './PayPalButton';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../Store/Store';
import { postSubscriptionData } from '../../../Store/action';
import { enqueueSnackbar } from 'notistack';
import LayerComponent from '../../../Component/Common/LayerComponent';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PayPalButtonComponent = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const { subscription, userId } = location.state;
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const subscriptionData = {
        "user_id": userId,
        "tag": subscription.tag,
        "price": subscription.price,
        "validity": subscription.validity,
        "access_level": subscription.access_level,
        "features": subscription.features,
    };
    // const handlePaymentSuccess = async (details: any) => {
    //     console.log(details)
    //     try {
    //         console.log(details)
    //         await dispatch(postSubscriptionData(subscriptionData)).unwrap();
    //         enqueueSnackbar('Premium Subscription successful', { variant: 'success' });
    //         navigate('/home');
    //     } catch (error: unknown) {
    //         if (error instanceof Error) {
    //             console.log(error.message);
    //             enqueueSnackbar(error.message, { variant: 'error' });
    //         } else {
    //             console.log('An unknown error occurred');
    //             enqueueSnackbar('An unknown error occurred', { variant: 'error' });
    //         }
    //     }
    // };

    const handlePaymentSuccess = async (details: any) => {
        console.log('Payment details:', details);

        const paymentInfo = {
            paymentMethod: 'PayPal',  // Since it's a PayPal payment
            payer_name: details.payer?.name?.given_name + ' ' + details.payer?.surname || '',
            payer_email: details.payer?.email_address || '',
            transaction_id: details.id || '',
            transaction_status: details.status || '',
            amount: details.purchase_units?.[0]?.amount?.value || '',
            currency: details.purchase_units?.[0]?.amount?.currency_code || '',
        };

        const updatedSubscriptionData = {
            ...subscriptionData,
            paymentInfo,
        };

        try {
            await dispatch(postSubscriptionData(updatedSubscriptionData)).unwrap();
            enqueueSnackbar('Premium Subscription successful', { variant: 'success' });
            navigate('/home');
        } catch (error: unknown) {
            if (error instanceof Error) {
                console.log(error.message);
                enqueueSnackbar(error.message, { variant: 'error' });
            } else {
                console.log('An unknown error occurred');
                enqueueSnackbar('An unknown error occurred', { variant: 'error' });
            }
        }
    };



    const handlePaymentError = (error: any) => {
        console.log(error);
        enqueueSnackbar('Payment failed', { variant: 'error' });
    };

    return (
        <div style={{
            width: '100%',
            height: '100%',
            zIndex: '100000',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div style={{ padding: '100px 50px', position: 'relative', backgroundImage: 'radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)', borderRadius: '11px' }}>
                <LayerComponent />
                <Typography sx={{
                    color: '#ffffff',
                    textAlign: 'center',
                    marginBottom: '12px'
                }}>{t('SelectPaymentMethod')}</Typography>
                <CloseIcon sx={{
                    position: 'absolute',
                    right: '5px',
                    top: '5px',
                    color: '#ffffff',
                    cursor: 'pointer'
                }} onClick={() => navigate('/home/subscriptionFinal')} />
                <PayPalButton
                    amount={subscription.price.toString()}
                    onSuccess={handlePaymentSuccess}
                    onError={handlePaymentError}
                />
            </div>
        </div>
    );
};

export default PayPalButtonComponent;

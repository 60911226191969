import requests from "../Axios/Instance";

interface SocialSignUpData {
  f_name: string;
  l_name: string;
  email: string;
  media_id: string;
  device_id: string;
  social_media_token: string;
  signup_type: string;
}

interface ResponseData<T> {
  data: T;
  msg: string;
  // other fields if necessary
}

const SocialMediaSignUpServices = {
  socialMediaSignUp: async (
    data: SocialSignUpData
  ): Promise<ResponseData<any>> => {
    try {
      return await requests.post(`/user/social_media`, data);
    } catch (error) {
      console.error("Error during sign up:", error);
      throw error; // Propagate the error
    }
  },
};

export default SocialMediaSignUpServices;

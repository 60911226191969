import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import './Slider.css';  // Import your custom CSS for the slider if needed
import Team1 from "../../Assets/Images/Teams/Team1.png";
import Team2 from "../../Assets/Images/Teams/Team2.png";
import UserProfile from "../../Assets/Images/bannerProfile.png"
import { Box, Typography, Divider } from "@mui/material";
import DefaultIMGBG from "../../Assets/Images/DefoultIMG.png";
import { CustomNextArrow, CustomPrevArrow } from "../Common/CustomeArrow";
import '../../Assets/CSS/Font/Typography/typography.css'
const poster1 = DefaultIMGBG;
const poster2 = DefaultIMGBG;

interface SliderProps {
    selectedEvents: matchData[];
    selectedBackground: string;
    storyBoxRefs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>;
    squareBoxRefs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>;
    selectedTypography: string;
}

interface matchData {
    league_key?: string;
    sport_key?: string;
    competitor1: competitorData;
    competitor2: competitorData;
    start: string;
    end: string;
    story_image?: string;
    square_image?: string;
    id?: string;
}

interface competitorData {
    name: string;
    profile_img: string;
}

const DataSlider: React.FC<SliderProps> = ({ selectedTypography, storyBoxRefs, squareBoxRefs, selectedEvents, selectedBackground }) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const sliderRef = useRef<Slider>(null);
    const timeZone: string = localStorage.getItem('timeZone') || '';
    console.log(timeZone)
    // Initialize refs
    useEffect(() => {
        // Initialize the refs arrays with the correct length
        storyBoxRefs.current = new Array(selectedEvents.length).fill(null).map(() => React.createRef<HTMLDivElement>());
        squareBoxRefs.current = new Array(selectedEvents.length).fill(null).map(() => React.createRef<HTMLDivElement>());
    }, [selectedEvents]);

    useEffect(() => {
        // Chunk selectedEvents into pairs
        const chunkedEvents = chunkArray(selectedEvents, 2);
        if (chunkedEvents.length > 0) {
            const lastChunk = chunkedEvents[chunkedEvents.length - 1];
            if (lastChunk.length === 1) {
                setCurrentIndex(prev => prev + 1);
            } else {
                setCurrentIndex(chunkedEvents.length - 1);
            }
        }
    }, [selectedEvents]);

    useEffect(() => {
        if (sliderRef.current) {
            (sliderRef.current as any).slickGoTo(currentIndex);
        }
    }, [currentIndex]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />, // Use custom previous arrow
        nextArrow: <CustomNextArrow />, // Use custom next arrow
        appendDots: (dots: React.ReactNode) => (
            <div style={{ position: 'absolute', bottom: '10px', width: '100%' }}>
                <ul className="dots-slick" style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center' }}>
                    {dots}
                </ul>
            </div>
        ),
        customPaging: (i: number) => (
            <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#FFF' }} />
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    // Helper function to chunk array into sub-arrays of size n
    const chunkArray = (array: matchData[], size: number): matchData[][] => {
        const chunkedArr: matchData[][] = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    };

    const formatDate = (dateString: string, timeZone: string) => {
        const date = new Date(dateString);

        const optionsDate: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            timeZone
        };

        const optionsTime: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone
        };

        const formattedDate = new Intl.DateTimeFormat('en-US', optionsDate).format(date);
        const formattedTime = new Intl.DateTimeFormat('en-US', optionsTime).format(date);

        return {
            date: formattedDate,
            time: formattedTime
        };
    };

    const handleImageLoad = () => {
        // Implement your image load logic here
    };
    const getTypographyStyle = () => {
        switch (selectedTypography) {
            case 'Typography 1':
                return { fontFamily: 'EduVICWANT, sans-serif', fontSize: '15px !important' };
            case 'Typography 2':
                return { fontFamily: 'Lobster, sans-serif', fontSize: '15px !important' };
            case 'Typography 3':
                return { fontFamily: 'YesevaOne, cursive', fontSize: '15px !important' };
            case 'Typography 4':
                return { fontFamily: 'LoveYaLikeASister, serif', fontSize: '15px !important' };
            default:
                return { fontFamily: 'WeddingGothicATF' };
        }
    };
    console.log(selectedTypography, getTypographyStyle())
    // Chunk selectedEvents into pairs
    const chunkedEvents = chunkArray(selectedEvents, 2);
    const userData = localStorage.getItem("user_profile");
    const userName = userData ? JSON.parse(userData)?.f_name : '';
    const userImage = userData ? JSON.parse(userData)?.establishment.logo : '';
    const userAddress = userData ? JSON.parse(userData)?.establishment.address : '';
    console.log(storyBoxRefs.current)
    return (
        <div className={`slider-container ${selectedEvents.length === 1 ? 'single-event' : ''}`} style={{ position: 'relative', width: '1120px', margin: '0 auto' }}>
            <Slider ref={sliderRef} {...settings}>
                {chunkedEvents.map((eventPair, chunkIndex) => (
                    <Box
                        key={chunkIndex}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            flexDirection: 'row', // Arrange boxes horizontally
                            gap: '23px',
                        }}
                    >
                        {eventPair.map((event, eventIndex) => {
                            const { date, time } = formatDate(event.start, timeZone);
                            const storyBoxRefIndex = chunkIndex * 2 + eventIndex;
                            const squareBoxRefIndex = chunkIndex * 2 + eventIndex;
                            return (
                                <React.Fragment key={event?.id}>
                                    <Box
                                        ref={storyBoxRefs.current[storyBoxRefIndex]}
                                        sx={{
                                            backgroundImage: `url(${selectedBackground || poster1})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: '452px',
                                            width: '248px',
                                            margin: '10px',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex !important',
                                                flexDirection: 'column !important',
                                                alignItems: 'center',
                                                width: '100%',
                                                gap: '20px',
                                                position: 'relative'
                                            }}
                                        >
                                            <Box sx={{
                                                marginTop: '-127px', flexDirection: 'column !important',
                                                height: '50px',
                                                justifyContent: 'space-evenly !important',
                                                alignItems: 'center !important'
                                            }}>
                                                <Typography
                                                    variant="h4"
                                                    sx={{

                                                        lineHeight: '5px',

                                                        color: '#FFFFFF',
                                                        letterSpacing: '2px',
                                                        wordSpacing: '1px',
                                                        fontSize: '13px !important',
                                                        fontVariant: 'all-petite-caps',
                                                        ...getTypographyStyle(),
                                                    }}
                                                >
                                                    {date}
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    sx={{

                                                        lineHeight: '1px',
                                                        color: '#FFFFFF',
                                                        letterSpacing: '2px',
                                                        wordSpacing: '1px',
                                                        fontSize: '13px !important',
                                                        fontVariant: 'all-petite-caps',
                                                        ...getTypographyStyle()
                                                    }}
                                                >
                                                    {time}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    marginTop: 2,
                                                    gap: '35px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    src={event.competitor1.profile_img || Team1}
                                                    alt="Team 1"
                                                    style={{ height: 60 }}
                                                    // crossOrigin="anonymous"
                                                    onLoad={handleImageLoad}
                                                />
                                                <Typography sx={{
                                                    color: '#FFFFFF',
                                                    letterSpacing: '3px',
                                                    ...getTypographyStyle()
                                                }}>VS</Typography>
                                                <img
                                                    src={event.competitor2.profile_img || Team2}
                                                    alt="Team 2"
                                                    style={{ height: 60 }}
                                                    // crossOrigin="anonymous"
                                                    onLoad={handleImageLoad}
                                                />
                                            </Box>
                                            <Box sx={{
                                                flexDirection: 'column !important',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                bottom: '5px'
                                            }}>
                                                <img
                                                    src={`${process.env.REACT_APP_FILE_URL}/logo/${userImage}` || UserProfile}
                                                    alt="user image"
                                                    style={{ height: 40 }}

                                                    onLoad={handleImageLoad}
                                                />
                                                <Typography sx={{
                                                    letterSpacing: '3px',
                                                    fontSize: '8px',
                                                    color: '#ffffffbf',
                                                    fontVariant: 'all-petite-caps',
                                                    lineHeight: '20px',
                                                    ...getTypographyStyle()
                                                }}>{userName}</Typography>
                                                <Typography sx={{
                                                    letterSpacing: '2px',
                                                    fontSize: '8px',
                                                    color: '#ffffffbf',
                                                    fontVariant: 'all-petite-caps',
                                                    ...getTypographyStyle()
                                                }}>{userAddress}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        ref={squareBoxRefs.current[squareBoxRefIndex]}
                                        sx={{
                                            backgroundImage: `url(${selectedBackground || poster2})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: '267px',
                                            width: '267px',
                                            margin: '10px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '50px',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex !important',
                                                flexDirection: 'column !important',
                                                alignItems: 'center',
                                                width: '100%',
                                                gap: '20px',
                                                position: 'relative'
                                            }}
                                        >
                                            <Box sx={{
                                                marginTop: '-127px', flexDirection: 'column !important',
                                                height: '50px',
                                                justifyContent: 'space-evenly !important',
                                                alignItems: 'center !Important'
                                            }}>
                                                <Typography
                                                    variant="h4"
                                                    sx={{
                                                        lineHeight: '5px',
                                                        color: '#FFFFFF',
                                                        letterSpacing: '2px',
                                                        wordSpacing: '1px',
                                                        fontSize: '13px !important',
                                                        fontVariant: 'all-petite-caps',
                                                        ...getTypographyStyle()
                                                    }}
                                                >
                                                    {date}
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    sx={{
                                                        lineHeight: '1px',
                                                        color: '#FFFFFF',
                                                        letterSpacing: '2px',
                                                        wordSpacing: '1px',
                                                        fontSize: '13px !important',
                                                        fontVariant: 'all-petite-caps',
                                                        ...getTypographyStyle()
                                                    }}
                                                >
                                                    {time}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    marginTop: 2,
                                                    gap: '35px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    src={event.competitor1.profile_img || Team1}
                                                    alt="Team 1"
                                                    style={{ height: 60 }}
                                                    // crossOrigin="anonymous"
                                                    onLoad={handleImageLoad}
                                                />
                                                <Typography sx={{
                                                    color: '#FFFFFF',
                                                    letterSpacing: '3px',
                                                    ...getTypographyStyle()
                                                }}>VS</Typography>
                                                <img
                                                    src={event.competitor2.profile_img || Team2}
                                                    alt="Team 2"
                                                    style={{ height: 60 }}
                                                    // crossOrigin="anonymous"
                                                    onLoad={handleImageLoad}
                                                />
                                            </Box>
                                            <Box sx={{
                                                flexDirection: 'column !important',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                bottom: '5px'
                                            }}>
                                                <img
                                                    src={`${process.env.REACT_APP_FILE_URL}/logo/${userImage}` || UserProfile}
                                                    alt="user image"
                                                    style={{ height: 40 }}

                                                    onLoad={handleImageLoad}
                                                />
                                                <Typography sx={{
                                                    letterSpacing: '3px',
                                                    fontSize: '8px',
                                                    color: '#ffffffbf',
                                                    fontVariant: 'all-petite-caps',
                                                    lineHeight: '20px',
                                                    ...getTypographyStyle()
                                                }}>{userName}</Typography>
                                                <Typography sx={{
                                                    letterSpacing: '2px',
                                                    fontSize: '8px',
                                                    color: '#ffffffbf',
                                                    fontVariant: 'all-petite-caps',
                                                    ...getTypographyStyle(),
                                                }}>{userAddress}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {(eventPair.length === 2 && eventIndex === 0) && (
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            style={{ height: '352px', margin: '0 10px', alignSelf: 'center', background: '#36393c', width: '2px' }}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Box>
                ))}
            </Slider>
        </div>
    );
};

export default DataSlider;

import requests from "../Axios/Instance";

const FileUploadServices = {
  getFileUploadData: async (formData: FormData) => {
    return requests.post("/commonRoute/fileUpload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default FileUploadServices;

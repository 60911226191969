import React, { useEffect } from "react";
import axios from "axios";

type TimeZoneComponentProps = {
  setTimeZone: React.Dispatch<React.SetStateAction<string>>;
};

const TimeZoneComponent: React.FC<TimeZoneComponentProps> = ({
  setTimeZone,
}) => {
  useEffect(() => {
    // Method to get timezone from the device
    const getDeviceTimeZone = () => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timeZone;
    };

    // Fallback method to get timezone based on IP
    const getIPTimeZone = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        return response.data.timezone;
      } catch (error) {
        console.error("Error fetching IP-based timezone:", error);
        return "UTC";
      }
    };

    // Set the timezone, trying device first and falling back to IP-based if needed
    const setTimeZoneAsync = async () => {
      const deviceTimeZone = getDeviceTimeZone();
      if (deviceTimeZone) {
        setTimeZone(deviceTimeZone);
      } else {
        const ipTimeZone = await getIPTimeZone();
        setTimeZone(ipTimeZone);
      }
    };

    setTimeZoneAsync();
  }, [setTimeZone]);

  return null; // No UI rendering
};

export default TimeZoneComponent;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFileUploadData } from "../action";

interface FileUpload {
  data: string[];
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

interface FileUploadState {
  data: FileUpload[];
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

const initialState: FileUploadState = {
  data: [],
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getFileUploadData.fulfilled,
      (state, action: PayloadAction<FileUpload>) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data.push(action.payload); // Push the new FileUpload object into the data array
      }
    );
    builder.addCase(getFileUploadData.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.errorMessage = "";
    });
    builder.addCase(getFileUploadData.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage =
        action.payload?.message || "Failed to fetch file upload data";
    });
  },
});

export default fileUploadSlice.reducer;

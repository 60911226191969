import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';

const Home: React.FC = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box sx={{
                flexGrow: 1, p: 3,   padding: '0 !important',
            }}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default Home;

import React from 'react'
import { Container } from '@mui/material';
import LoginLogo from "../../../Assets/Images/Logo.png"

const Logo = () => {
    return (

        <>
            <Container>
                <img src={LoginLogo} alt="Home logo"  style={{height:" 70px" ,width:"110px"}}/>
            </Container>
        </>
    )
}

export default Logo
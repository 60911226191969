import React from "react";
import nextArrow from "../../Assets/Images/next.png"
import prevArrow from "../../Assets/Images/prev.png"
interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const CustomPrevArrow: React.FC<ArrowProps> = ({
  className,
  style,
  onClick,
}) => {
  return (
    <div
      className={className}
      style={{
        display: "block",
        backgroundImage: `url(${prevArrow})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",

        zIndex: 1, // Ensure the arrow appears above other elements
      }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow: React.FC<ArrowProps> = ({
  className,
  style,
  onClick,
}) => {
  return (
    <div
      className={className}
      style={{
        display: "block",
        backgroundImage: `url(${nextArrow})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",

        zIndex: 1, // Ensure the arrow appears above other elements
      }}
      onClick={onClick}
    />
  );
};

export { CustomPrevArrow, CustomNextArrow };

import requests from "../Axios/Instance";

interface SubscriptionsParams {
  user_id: string;
  tag: string;
  price: number;
  validity: number;
  access_level: number;
  features: features[];
}
interface features {
  title: string;
  description: string;
}
const SubscriptionBuyServices = {
  postSubscriptionData: async (body: SubscriptionsParams) => {
    return requests.post("/user/subscription/buy", body);
  },
};

export default SubscriptionBuyServices;

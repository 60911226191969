import React from 'react';
import { Box } from '@mui/material';

const LayerComponent = () => {
    return (
        <Box
            sx={{
                background: 'rgba(217, 217, 217, 1)',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 0,
                opacity: 0.05,
                borderRadius: '12px 0px 0px 0px',
            }}
        />
    );
}

export default LayerComponent;

// ----------------------------------------------------------------------

export function remToPx(value:any) {
    return Math.round(parseFloat(value) * 16);
  }
  
  export function pxToRem(value:any) {
    return `${value / 16}rem`;
  }
  interface IStyle {
    sm:any;
    md:any;
    lg:any;
}
  export function responsiveFontSizes({ sm, md, lg }:IStyle) {
    return {
      "@media (min-width:600px)": {
        fontSize: pxToRem(sm),
      },
      "@media (min-width:900px)": {
        fontSize: pxToRem(md),
      },
      "@media (min-width:1200px)": {
        fontSize: pxToRem(lg),
      },
    };
  }
  
  // ----------------------------------------------------------------------
  
  const FONT_PRIMARY = "Inter, BlairITC , Wedding Gothic ATF" ;
  // const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font
  
  const typography = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 350,
      lineHeight: 35 / 32,
      fontSize: pxToRem(45),
      ...responsiveFontSizes({ sm: 35, md: 42, lg: 48 }),
    },
    h2: {
      fontWeight: 550,
      lineHeight: 21 / 16,
      fontSize: pxToRem(15),
      ...responsiveFontSizes({ sm: 24, md: 31, lg: 36 }),
    },

    h3: {
      fontWeight: 550,
      lineHeight: 1.5,
      fontSize: pxToRem(10),
      ...responsiveFontSizes({ sm: 14, md: 18, lg: 20 }),
    },
    h4: {
      fontWeight: 350,
      lineHeight: 1.5,
      fontSize: pxToRem(8),
      ...responsiveFontSizes({ sm: 6, md: 8, lg: 10 }),
    },
    h5: {
      fontWeight: 500,
      lineHeight: 1,
      fontSize: pxToRem(15),
      ...responsiveFontSizes({ sm: 12, md: 15, lg: 18 }),
    },
    h6: {
      fontWeight: 300,
      lineHeight: 22 / 18,
      fontSize: pxToRem(16),
      ...responsiveFontSizes({ sm: 14, md: 16, lg: 16 }),
    },
   

    subtitle1: {
      fontWeight: 500,
      lineHeight: 1,
      fontSize: pxToRem(10),
    },
    subtitle2: {
      fontWeight: 500,
      lineHeight: 0.5,
      fontSize: pxToRem(11),
    },
    body1: {
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      fontWeight: 600,
      
    },
    body2: {
      lineHeight: 1.5,
      fontSize: pxToRem(8),
      fontWeight: 400,
      ...responsiveFontSizes({ sm: 6, md: 7, lg: 8 }),
    },

    caption: {
      fontWeight: 300,
      lineHeight: 1,
      fontSize: pxToRem(8),
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      textTransform: "uppercase",
    },
    button: {
      fontWeight: 700,
      lineHeight: 24 / 14,
      fontSize: pxToRem(12),
      textTransform: "capitalize",
    },
  };
  
  export default typography;
  
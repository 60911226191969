import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, Checkbox, FormControlLabel } from '@mui/material';
import Logo from './Logo'; // Ensure you have this component
import { useFormik } from 'formik'; // Import useFormik
import AppleIMG from "../../../Assets/Images/Login/AppleIMG.png";
import GoogleIMG from "../../../Assets/Images/Login/GoogleIMG.png";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import LayerComponent from '../../../Component/Common/LayerComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../Store/Slice/authSlice';
import { customerSignIn, postSubscriptionData, socialMediaSignUp } from '../../../Store/action';
import { AppDispatch, RootState } from '../../../Store/Store'; // Import your AppDispatch type
import GenerateDeviceId from '../../../Component/GenerateDeviceId';
// import { useSnackbar } from '../../../Component/Common/SnackbarProvider';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import SubscriptionPlansServices from '../../../Services/SubscriptionPlansServices';

const useStyles = makeStyles((theme) => ({
    customTextField: {
        '& .MuiInputBase-input': {
            padding: '6px 12px !important',
            color: '#fff',
            '&::placeholder': {
                fontFamily: "Inter",
                fontWeight: 300,
                fontStyle: "italic"
            }
        },

        '& .MuiOutlinedInput-root': {
            borderRadius: "10px",
            fontSize: "12px",
            '& fieldset': {
                borderColor: '#AFAFAF',
            },
            '&:hover fieldset': {
                borderColor: '#AFAFAF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#AFAFAF',
            },
        },
        width: "60%"
    },
}));
declare global {
    interface Window {
        AppleID: any;
    }
}
interface LoginValues {
    email: string;
    password: string;
    rememberMe: boolean;
}

interface LoginObj {
    username: string;
    password: string;
    device_id: string;
}
interface SocialSignUpData {
    f_name: string;
    l_name: string;
    email: string;
    media_id: string;
    device_id: string;
    social_media_token: string;
    signup_type: string;
}
type ErrorWithResponse = Error & {
    response?: {
        data?: {
            message?: string;
        };
    };
};
interface AppleIdToken {
    iss: string; // Issuer Identifier for the Issuer of the response.
    aud: string; // Your client_id (the one you used to authenticate)
    exp: number; // Expiration time of the token in seconds since the UNIX epoch
    iat: number; // Time the token was issued
    sub: string; // The unique identifier for the user (user ID)
    email?: string; // The user’s email address
    email_verified?: string; // "true" if the email is verified
    is_private_email?: string; // "true" if the email is a private relay email
    auth_time?: number; // Time when the user last authenticated
    nonce?: string; // Optional nonce sent in the request
    nonce_supported?: boolean; // "true" if the nonce claim is supported
    given_name?: string; // User's first name (if available)
    family_name?: string; // User's last name (if available)
}
interface subscription {
    tag: string;
    price: number;
    validity: number;
    features: features[];
    img?: string;
    access_level: number;
}
interface features {
    title: string;
    description: string;
}
export function isErrorWithResponse(error: any): error is { response: { data: { message: string } } } {
    return error && error.response && error.response.data && typeof error.response.data.message === 'string';
}


const Login: React.FC = () => {
    const { t, i18n } = useTranslation();

    const classes = useStyles();
    const dispatch = useDispatch<AppDispatch>(); // Type your dispatch function
    const deviceId = useSelector((state: RootState) => state.device.deviceId);
    const navigate = useNavigate();
    const [subscriptionPlans, setSubscriptionPlans] = useState<subscription[]>([]);
    const [freeSubscriptionData, setFreeSubscriptionData] = useState<subscription[]>([])
    console.log('Device ID from Redux:', deviceId); // Debugging line
    // const { showSnackbar } = useSnackbar();


    const formik = useFormik<LoginValues>({
        initialValues: {
            email: '',
            password: '',
            rememberMe: false, // Add rememberMe field
        },
        validate: values => {
            const errors: { email?: string; password?: string } = {};
            if (!values.email) {
                errors.email = 'Required';
            }
            if (!values.password) {
                errors.password = 'Required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            if (!deviceId) {
                console.error('Device ID is missing');
                return;
            }

            const obj = {
                username: values.email,
                password: values.password,
                device_id: deviceId,
            };

            try {
                const res = await dispatch(customerSignIn(obj)).unwrap();
                console.log(res);

                // Remember Me Logic
                if (values.rememberMe) {
                    localStorage.setItem('email', values.email);
                    localStorage.setItem('password', values.password);
                } else {
                    localStorage.removeItem('email');
                    localStorage.removeItem('password');
                }

                enqueueSnackbar(res.msg, { variant: 'success' });
                navigate('/home');
            } catch (error: any) {
                console.log(error.message);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
            localStorage.removeItem('selectedEvents');
        },
    });

    useEffect(() => {
        const savedEmail = localStorage.getItem('email');
        const savedPassword = localStorage.getItem('password');

        if (savedEmail && savedPassword) {
            formik.setValues({
                email: savedEmail,
                password: savedPassword,
                rememberMe: true, // Set rememberMe to true if credentials are found
            });
        }
    }, []);

    useEffect(() => {
        SubscriptionPlansServices.getSubscriptionPlanData()
            .then(res => {
                const plans = res.data.data;
                const freePlans = plans.filter((plan: any) => plan.tag === 'Free');
                const premiumPlans = plans.filter((plan: any) => plan.tag === 'Premium');
                console.log(plans)
                setFreeSubscriptionData(freePlans);
                setSubscriptionPlans(premiumPlans);
            })
            .catch(error => {
                console.error('Error fetching subscription plans:', error);
            });
    }, []);

    console.log(freeSubscriptionData)
    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log('Google token', tokenResponse.access_token);

            try {
                // Fetch user info using the token
                const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${tokenResponse.access_token}`,
                    },
                });
                console.log(userInfoResponse)
                const { given_name, family_name, email, sub } = userInfoResponse.data; // `sub` is the unique user ID (media_id)

                // Prepare the payload for your API
                const payload: SocialSignUpData = {
                    f_name: given_name,
                    l_name: family_name,
                    email: email,
                    media_id: sub, // unique Google user ID
                    device_id: deviceId, // assuming deviceId is available
                    social_media_token: tokenResponse.access_token, // the access token from Google
                    signup_type: "Google",
                };

                // Call your API to log in with the social media account
                const response = await dispatch(socialMediaSignUp(payload)).unwrap();
                const userId = response.data.id;
                const subscriptionData = {
                    "user_id": userId,
                    "tag": freeSubscriptionData[0].tag,
                    "price": freeSubscriptionData[0].price,
                    "validity": freeSubscriptionData[0].validity,
                    "access_level": freeSubscriptionData[0].access_level,
                    "features": freeSubscriptionData[0].features,
                };

                try {
                    await dispatch(postSubscriptionData(subscriptionData)).unwrap();
                    enqueueSnackbar("Successfully login with Google", { variant: 'success' });
                    navigate('/home');
                } catch (err) {
                    console.log(err)
                    if (err instanceof Error) {
                        console.error('Error:', err.message);
                        enqueueSnackbar(err.message, { variant: 'error' });
                    } else {
                        console.error('An unexpected error occurred');
                        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
                    }
                }

            } catch (error: any) {
                console.error('Error during Google login or API call', error);
                enqueueSnackbar('Failed to login with Google', { variant: 'error' });
            }
        },
        onError: () => {
            console.error('Login Failed');
            enqueueSnackbar('Google login failed', { variant: 'error' });
        },
    });
    const appleLogin = async () => {
        try {
            // Initiate Apple sign-in
            const response = await window.AppleID.auth.signIn();
            console.log(response);

            if (response && response.authorization && response.authorization.id_token) {
                const idToken = response.authorization.id_token;

                // Decode the ID token to extract user information
                const decodedToken: AppleIdToken = jwtDecode(idToken);

                // Extract the email and sub (unique Apple user ID)
                const { email, sub } = decodedToken;

                // Get the user's name from the response if available (only available on first sign-in)
                const { givenName, familyName } = response.user?.name || {};

                console.log(decodedToken);
                console.log('First Name:', givenName);
                console.log('Last Name:', familyName);

                // Prepare the payload for your API
                const payload: SocialSignUpData = {
                    f_name: givenName || '',
                    l_name: familyName || '',
                    email: email || '',
                    media_id: sub, // unique Apple user ID
                    device_id: deviceId, // Replace with your actual device ID
                    social_media_token: idToken, // Token to be verified by the backend
                    signup_type: "Apple",
                };

                // Dispatch the sign-in action to your Redux store
                const apiResponse = await dispatch(socialMediaSignUp(payload)).unwrap();
                const userId = apiResponse.data.id;
                const subscriptionData = {
                    "user_id": userId,
                    "tag": freeSubscriptionData[0].tag,
                    "price": freeSubscriptionData[0].price,
                    "validity": freeSubscriptionData[0].validity,
                    "access_level": freeSubscriptionData[0].access_level,
                    "features": freeSubscriptionData[0].features,
                };

                try {
                    await dispatch(postSubscriptionData(subscriptionData)).unwrap();
                    enqueueSnackbar("Successfully login with Apple", { variant: 'success' });
                    navigate('/home/main');
                } catch (err) {
                    console.log(err)
                    if (err instanceof Error) {
                        console.error('Error:', err.message);
                        enqueueSnackbar(err.message, { variant: 'error' });
                    } else {
                        console.error('An unexpected error occurred');
                        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
                    }
                }
                // Display success message and navigate to home
            } else {
                // Handle case where Apple sign-in fails
                enqueueSnackbar('Apple login failed', { variant: 'error' });
            }
        } catch (error: any) {
            // Handle errors
            console.error('Error during Apple login', error);
            enqueueSnackbar('Failed to login with Apple', { variant: 'error' });
        }
    };


    useEffect(() => {
        window.AppleID.auth.init({
            clientId: 'com.prometteur.allsports.service', // Replace with your actual Service ID
            scope: 'name email',
            redirectURI: 'https://allsports.prometteur.in/', // Replace with your actual redirect URI
            usePopup: true, // Set to true to use a popup instead of redirect
        });
    }, []);
    return (
        <Box sx={{
            backgroundColor: "#0F0F0F",
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        }}>
            <GenerateDeviceId />
            <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                <Logo />
            </Box>
            <Box sx={{
                borderRadius: "20px",
                padding: "28px",
                width: "340px",
                border: '1px solid #2E2E2E',
                background: 'radial-gradient(59.32% 80.35% at 50% 0%, rgba(26, 26, 26, 0.8) 0%, rgba(13, 13, 13, 0.8) 100%)',
                boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                position: "relative",
                overflow: "hidden"
            }}>
                <Box sx={{
                    background: 'rgba(217, 217, 217, 1)',
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                    zIndex: "0",
                    opacity: "5%",
                    borderRadius: '20px 0px 0px 0px'
                }}></Box>
                <Box sx={{
                    position: 'relative',
                    zIndex: '1',
                }}>
                    <Typography variant="subtitle2" align="center" sx={{ color: 'white', marginBottom: 3 }}>
                        {t('ConnectWith')}
                    </Typography>
                    <Box sx={{ display: 'flex', marginBottom: 3, justifyContent: "center", gap: "45px" }}>
                        <Button onClick={() => appleLogin()} variant="contained" sx={{
                            width: '92px',
                            height: '68px',
                            background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                            position: "relative",
                            overflow: "hidden",
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 0,
                            border: "1px solid #2D2D2D",
                            boxShadow: "none",
                            borderRadius: "12px",

                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '-30px',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                borderRadius: "12px",
                                background: "radial-gradient(circle at top, #434343a8, transparent)",
                                opacity: 0,
                                transition: 'opacity 0.3s',
                                boxShadow: "none",
                            },
                            '&:hover::before': {
                                opacity: 1,
                            },
                            '&:hover': {
                                boxShadow: "none", // Ensure no box shadow on hover
                            },
                        }}>
                            <LayerComponent />
                            <Box sx={{
                                position: 'relative',
                                zIndex: '1',
                                height: '50%'
                            }}>
                                <img src={AppleIMG} alt="AppleIMG" style={{ height: '31px', zIndex: "2" }} />
                            </Box>
                        </Button>
                        <Button onClick={() => googleLogin()} variant="contained" sx={{
                            width: '92px',
                            height: '68px',
                            background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                            position: "relative",
                            overflow: "hidden",
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 0,
                            border: "1px solid #2D2D2D",
                            boxShadow: "none",
                            borderRadius: "12px",

                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '-30px',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                borderRadius: "12px",
                                background: "radial-gradient(circle at top, #434343a8, transparent)",
                                opacity: 0,
                                transition: 'opacity 0.3s',
                                boxShadow: "none",
                            },
                            '&:hover::before': {
                                opacity: 1,
                            },
                            '&:hover': {
                                boxShadow: "none", // Ensure no box shadow on hover
                            },
                        }}>
                            <LayerComponent />
                            <Box sx={{
                                position: 'relative',
                                zIndex: '1',
                                height: '50%'
                            }}>
                                <img src={GoogleIMG} alt="GoogleIMG" style={{ height: '31px', zIndex: "2" }} />
                            </Box>
                        </Button>
                    </Box>
                    <Box sx={{ borderBottom: '1px solid #FFFFFF', marginBottom: 3, marginTop: "24px", }}></Box>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "40px" }}>
                            <Box sx={{
                                display: 'flex', flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontFamily: "Inter", fontSize: "11px" }}>{t('Email')}* :</Typography>
                                <TextField
                                    id="email"
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    placeholder='exemple@email.com'
                                    className={classes.customTextField}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex', flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontFamily: "Inter", fontSize: "11px" }}>{t('Password')}* :</Typography>
                                <TextField
                                    id="password"
                                    name="password"
                                    type="password"
                                    variant="outlined"
                                    placeholder='*********'
                                    className={classes.customTextField}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: '25px' }}>
                            <FormControlLabel sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                justifyContent: 'flex-end',
                                marginLeft: '0px'
                            }}
                                control={
                                    <Checkbox
                                        id="rememberMe"
                                        name="rememberMe"
                                        checked={formik.values.rememberMe}
                                        onChange={formik.handleChange}
                                        sx={{
                                            color: '#FFFFFF', // Change the unchecked box color
                                            '&.Mui-checked': {
                                                color: '#FFFFFF', // Change the checked box color
                                            },
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 24, // Adjust the size of the checkbox
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                            color: '#FFFFFF', // Label text color
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {t('RememberMe')}
                                    </Typography>
                                }
                            />
                        </Box>
                        <Box sx={{ borderBottom: '1px solid #FFFFFF', marginBottom: 3, marginTop: "24px", }}></Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "12px",
                        }}>
                            <Button type="submit" variant="contained" color="primary" sx={{
                                width: '40%',
                                padding: '8px 24px',
                                borderRadius: '45px ',
                                border: "0.6px solid #2D2D2D",
                                background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                                boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
                                position: "relative",
                                overflow: "hidden",
                                '&:hover': {
                                    background: '#FFFFFF',
                                    color: '#000000',
                                    cursor: 'pointer',
                                },
                            }}>
                                <LayerComponent />
                                <Box sx={{
                                    position: 'relative',
                                    zIndex: '1',
                                }} >
                                    {t('Connection')}
                                </Box>

                            </Button>
                        </Box>
                    </form>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "12px",
                    }}>
                        <Button
                            variant="text"

                            sx={{
                                color: '#fff',
                                position: 'relative',
                                padding: '0',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    width: '56%',
                                    height: '0.5px',
                                    backgroundColor: '#FFFFFF',
                                    bottom: '3px', // Adjust this value to control the gap
                                    left: '0',
                                    marginLeft: "23px"
                                },
                                width: "40%",
                            }}
                            onClick={() => navigate("/subscription")}
                        >
                            {t('Registration')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;

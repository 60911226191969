import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LanguageState {
  lang: string;
}

const initialState: LanguageState = {
  lang: "en", // Default language
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      state.lang = action.payload;
      localStorage.setItem(
        "user_profile",
        JSON.stringify({ lang: action.payload })
      );
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;

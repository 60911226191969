import React, { useState } from 'react';
import SubscriptionOne from './SubscriptionOne';
import SubscriptionTwo from './SubscriptionTwo';
import SubscriptionThree from './SubscriptionThree';
import { Box, Button, Typography } from '@mui/material';
import Logo from '../Login/Logo';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the icon
import LayerComponent from '../../../Component/Common/LayerComponent';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    mainContainer: {
        backgroundColor: '#0F0F0F',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    formContainer: {
        borderRadius: '25px',
        padding: '25px',
        maxWidth: '1100px',
        color: '#FFFFFF',
        width: "1110px",
        border: '1px solid #2E2E2E',
        background: 'radial-gradient(59.32% 80.35% at 50% 0%, rgba(26, 26, 26, 0.8) 0%, rgba(13, 13, 13, 0.8) 100%)',
        // boxShadow: "0px 4px 4px 0px #00000040",
        boxShadow: "0px 2px 3px 0px #0B0B0B63 inset",
        position: "relative",
        overflow: "hidden"
    },
    navigation: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
        width: "100%"
    },
    submitButton: {
        width: '100%',
        padding: '12px',
        borderRadius: '8px',
        border: '1px solid #2D2D2D',
        backgroundColor: '#1A1A1A',
        color: '#fff',
        textTransform: 'none',
        marginTop: '20px',
    },
}));
interface EstablishmentData {
    logo: string;
    type: string;
    activity: string;
    address: string;
    company_name: string;
    tva_number: string;
}

interface SignUpData {
    f_name: string;
    l_name: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword?: string;
    lang: string;
    device_id: string;
    establishment: EstablishmentData;
}
interface subscription {
    tag: string;
    price: number;
    validity: number;
    features: features[];
    img?: string;
    access_level: number;
}
interface features {
    title: string;
    description: string;
}
interface memberData {
    setMemberData: React.Dispatch<React.SetStateAction<Partial<subscription>>>;

}
const MultiStepForm = ({ setMemberData }: memberData) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [step, setStep] = useState<number>(1);
    const classes = useStyles();
    const [signUpData, setSignUpData] = useState<Partial<SignUpData>>({});
    const [logoImg, setLogoImg] = useState<File | null>(null);

    const renderStep = () => {
        switch (step) {
            case 1:
                return <SubscriptionOne setSignUpData={setSignUpData} setStep={setStep} />;
            case 2:
                return <SubscriptionTwo setSignUpData={setSignUpData} setStep={setStep} setLogoImg={setLogoImg} />;
            case 3:
                return <SubscriptionThree signUpData={signUpData} logoImg={logoImg} setMemberData={setMemberData} />;
            default:
                return <SubscriptionOne setSignUpData={setSignUpData} setStep={setStep} />;
        }
    };

    const handleNext = () => {
        console.log('Next button clicked');
        if (step < 3) {
            setStep(prevStep => prevStep + 1);
        }
    };

    const handleBack = () => {
        console.log('Back button clicked');
        if (step > 1) {
            setStep(prevStep => prevStep - 1);
        }
        if (step === 1) {
            navigate('/');
        }
    };

    return (
        <Box className={classes.mainContainer}>
            <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
                <Logo />
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',

            }}>
                <Box className={classes.formContainer}>
                    <LayerComponent />
                    <Box sx={{
                        position: 'relative',
                        zIndex: '1',
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                                <Typography variant="h3" sx={{ fontFamily: "BlairITC", LineHeight: "44px", textTransform: 'uppercase' }}>{t('Registration')}</Typography>
                                <Typography variant="h4" sx={{ marginBottom: "0", lineHeight: "8px", fontFamily: "Wedding Gothic ATF", fontSize: "10px", color: "#ffffffbd", textTransform: 'uppercase' }}>{t('joinAllsportsToday')}</Typography>
                            </Box>
                            <Typography variant="h5" sx={{ color: '#FFFFFF', fontSize: "16px" }}>{t('Stage')} {step}/3</Typography>
                        </Box>
                        {renderStep()}
                    </Box>

                </Box>
                <Box className={classes.navigation}>
                    <Button onClick={handleBack} variant="text" sx={{
                        color: '#FFFFFF',
                        padding: '4px 24px 4px 24px',
                        border: " 0.6px solid #2D2D2D",
                        position: 'relative',
                        borderRadius: "45px",
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            width: '53%',
                            height: '0.5px',
                            backgroundColor: '#FFFFFF',
                            bottom: '3px', // Adjust this value to control the gap
                            left: '0',
                            transition: 'transform 0.3s',
                            transform: 'scaleX(0)',
                            marginLeft: "25px"

                        },
                        '&:hover::after': {
                            transform: 'scaleX(1)',

                        },

                    }}>
                        <ArrowBackIcon sx={{ marginRight: '8px', color: "#fff" }} />{t('Back')}
                    </Button>
                    {/* <Button onClick={handleNext} disabled={step === 3} sx={{
                        padding: '4px 24px 4px 24px',
                        borderRadius: '45px ',
                        border: '1px solid #2D2D2D',
                        background: "radial-gradient(59.32% 80.35% at 50% 0%, #1A1A1A 0%, #0D0D0D 100%)",
                        boxShadow: "none",
                        color: "#FFFFFF"
                    }}>
                        Suivant
                    </Button> */}
                </Box>
            </Box>

        </Box>
    );
};

export default MultiStepForm;

import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthServices from "../Services/AuthServices";
import LeagueServices from "../Services/LeagueServices";
import SportsServices from "../Services/SportsServices";
import MainLeagueServices from "../Services/MainLeagueServices";
import BackgroundServices from "../Services/BackgroundServices";
import MatchesServices from "../Services/MatchesServices";
import FileUploadServices from "../Services/FileUploadServices";
import MultimediaServices from "../Services/MultimediaServices";
import MultimediaGetServices from "../Services/MultimediaGetServices";
import ProfileServices from "../Services/ProfileServices";
import SubscriptionPlansServices from "../Services/SubscriptionPlansServices";
import SubscriptionBuyServices from "../Services/SubscriptionBuyServices";
import UpdateProfileServices from "../Services/UpdateProfileServices";
import SocialMediaSignUpServices from "../Services/SocialMediaSignUpServices";

interface SignInBody {
  username: string;
  password: string;
  device_id: string;
}
interface Establishment {
  logo: string;
  type: string;
  activity: string;
  address: string;
  company_name: string;
  tva_number: string;
}
interface League {
  id: string;
  name: string;
  league_banner: string;
  sport_key: string;
  league_name: string;
}

interface ResponseData {
  data: League[];
  msg: string;
}

interface signUpEstablishment {
  logo: string;
  type: string;
  activity: string;
  address: string;
  company_name: string;
  tva_number: string;
}

interface UserData {
  email: string;
  phone: string;
  f_name: string;
  l_name: string;
  role: string;
  lang: string;
  establishment: signUpEstablishment;
  signup_type: string;
  media_id: string;
  is_deleted: boolean;
  auth_token: string;
  created_at: string;
  updated_at: string;
  id: string;
}

interface SignUpResponse {
  status: number;
  msg: string;
  data: UserData;
}
interface RejectValue {
  message: responseData;
}
interface responseData {
  data: { msg: string }
}
interface fileReject {
  message: string;
}
interface Sport {
  sport_img: string;
  title: string;
  description: string;
}

interface SportResponseData {
  data: Sport[];
}

interface Bg {
  img_url: string;
}
interface matchData {
  league_key: string;
  sport_key: string;
  competitor1: competitorData[];
  competitor2: competitorData[];
}
interface competitorData {
  name: string;
  profile_img: string;
}
interface Matches {
  _id: string;
  records: matchData[];
}

interface multimediaState {
  status: number;
  msg: string
  data: null;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}

interface MultimediaParams {
  competitor1: competitorData;
  competitor2: competitorData;
  start: string;
  end: string;
  story_img: string;
  web_img: string;
}
interface competitorData {
  name: string;
  profile_img: string;
}
interface ResponseBody {
  status: number;
  msg: string;
  data: any[] | null; // Update to represent an array or null
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}
interface ProfileEstablishmentData {
  logo: string;
  type: string;
  activity: string;
  address: string;
  company_name: string;
  tva_number: string;
}

interface profileBodyData {
  email: string;
  phone: string;
  f_name: string;
  l_name: string;
  role: string;
  lang: string;
  establishment: ProfileEstablishmentData;
  signup_type: string;
  media_id: string;
  subscription: string;
  id: string;
}
interface profileDataBody {
  data: profileBodyData;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}


interface FileUpload {
  data: string[];
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
}
interface EstablishmentData {
  logo: string;
  type: string;
  activity: string;
  address: string;
  company_name: string;
  tva_number: string;
}

interface SignUpData {
  f_name: string;
  l_name: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword?: string;
  lang: string;
  device_id: string;
  establishment: EstablishmentData;
}
interface subscription {
  tag: string;
  price: number;
  validity: number;
  features: features[];
}
interface features {
  title: string;
  description: string;
}
interface SubscriptionsParams {
  user_id: string;
  tag: string;
  price: number;
  validity: number;
  access_level: number;
  features: features[];
}
interface features {
  title: string;
  description: string;
}
interface ProfileDataParams {
  user_id: string;
  f_name: string;
  l_name: string;
  phone: string;
  lang: string;
  establishment: establishmentData;
}
interface establishmentData {
  logo: string;
  type: string;
  activity: string;
  address: string;
  company_name: string;
  tva_number: string;
}
interface SocialSignUpData {
  f_name: string;
  l_name: string;
  email: string;
  media_id: string;
  device_id: string;
  social_media_token: string;
  signup_type: string;
}
export const customerSignIn = createAsyncThunk<ResponseData, SignInBody, { rejectValue: RejectValue }>('/user/login',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await AuthServices.customerSignIn(body);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.response.data.msg });
    }
  }
);

export const customerSignUp = createAsyncThunk<SignUpResponse, SignUpData, { rejectValue: RejectValue }>(
  '/user/signup',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await AuthServices.customerSignUp(body);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.response.data.msg });
    }
  }
);
export const customerLogOut = createAsyncThunk<SignUpResponse, { rejectValue: RejectValue }>(
  '/user/signup',
  async ({ }, { rejectWithValue }) => {
    try {
      const { data } = await AuthServices.customerLogOut();
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.response.data.msg });
    }
  }
);
export const socialMediaSignUp = createAsyncThunk<SignUpResponse, SocialSignUpData, { rejectValue: RejectValue }>(
  '/user/signup',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await SocialMediaSignUpServices.socialMediaSignUp(body);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const getRandomLeagues = createAsyncThunk<League[], number, { rejectValue: RejectValue }>('/leagues',
  async (limit, { rejectWithValue }) => {
    try {
      const { data } = await LeagueServices.getRandomLeagues(limit);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const getSportData = createAsyncThunk<Sport[], { pageNo: number, limit: number }, { rejectValue: RejectValue }>('/sports',
  async ({ pageNo, limit }, { rejectWithValue }) => {
    try {
      const { data } = await SportsServices.getSportData(pageNo, limit);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const getMainLeagues = createAsyncThunk<League[], { sportKey: string, pageNo: number, limit: number }, { rejectValue: RejectValue }>('/sports',
  async ({ sportKey, pageNo, limit }, { rejectWithValue }) => {
    try {
      const { data } = await MainLeagueServices.getMainLeagues(sportKey, pageNo, limit);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const getBackgroundData = createAsyncThunk<Bg[], { pageNo: number, limit: number }, { rejectValue: RejectValue }>('/bgImages',
  async ({ pageNo, limit }, { rejectWithValue }) => {
    try {
      const { data } = await BackgroundServices.getBackgroundData(pageNo, limit);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const getMatchData = createAsyncThunk<Matches[], {
  sportKey: string,
  leagueKey: string,
  startDate: string,
  endDate: string,
  timeZone: string;
}, { rejectValue: RejectValue }>('/sports',
  async ({ sportKey, leagueKey, startDate, endDate, timeZone }, { rejectWithValue }) => {
    try {
      const { data } = await MatchesServices.getMatchData(sportKey, leagueKey, startDate, endDate, timeZone);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);
export const getFileUploadData = createAsyncThunk<FileUpload, FormData, { rejectValue: fileReject }>(
  '/fileUpload',
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const { data } = await FileUploadServices.getFileUploadData(formData);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const postMultimediaData = createAsyncThunk<ResponseBody[], MultimediaParams, { rejectValue: RejectValue }>(
  '/multimedia',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await MultimediaServices.postMultimediaData(body);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const getMultimediaData = createAsyncThunk<Matches[], {
  startDate: string,
  endDate: string,
  timeZone: string
}, { rejectValue: RejectValue }>('/sports',
  async ({ startDate, endDate, timeZone }, { rejectWithValue }) => {
    try {
      const { data } = await MultimediaGetServices.getMultimediaData(startDate, endDate, timeZone);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const getProfileData = createAsyncThunk<profileBodyData, void, { rejectValue: { message: string } }>(
  '/user/profile',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ProfileServices.getProfileData();
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const getSubscriptionPlanData = createAsyncThunk<subscription[], void, { rejectValue: { message: string } }>(
  '/subscription',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await SubscriptionPlansServices.getSubscriptionPlanData();
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const postSubscriptionData = createAsyncThunk<ResponseBody[], SubscriptionsParams, { rejectValue: RejectValue }>(
  '/subscriptionBuy',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await SubscriptionBuyServices.postSubscriptionData(body);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const putprofileData = createAsyncThunk<ResponseBody[], ProfileDataParams, { rejectValue: RejectValue }>(
  '/updateProfile',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await UpdateProfileServices.putprofileData(body);
      return data;
    } catch (error: any) {
      return rejectWithValue({ message: error.message });
    }
  }
);


import React from 'react';
import { PayPalScriptProvider, PayPalButtons, ReactPayPalScriptOptions } from '@paypal/react-paypal-js';

interface PayPalButtonProps {
    amount: string;
    onSuccess: (details: any) => void;
    onError: (error: any) => void;
}

const PayPalButton: React.FC<PayPalButtonProps> = ({ amount, onSuccess, onError }) => {
    const options: ReactPayPalScriptOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || '',  // Correctly use camelCase clientId
    };

    return (
        <PayPalScriptProvider options={options}>
            <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        intent: "CAPTURE", // Added intent property
                        purchase_units: [{
                            amount: {
                                currency_code: "USD",
                                value: amount
                            }
                        }]
                    });
                }}
                onApprove={async (data, actions) => {
                    if (actions.order) {
                        const details = await actions.order.capture();
                        onSuccess(details);
                    }
                }}
                onError={(error) => onError(error)}
            />
        </PayPalScriptProvider>
    );
};

export default PayPalButton;

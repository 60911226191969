import React from 'react';
import { Box, Typography, TextField, Button, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import LayerComponent from '../../../Component/Common/LayerComponent';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    formField: {
        marginBottom: '20px',
    },
    LongTextField: {
        '& .MuiInputBase-input': {
            padding: '6px 12px !important',
            color: '#fff',
            '&::placeholder': {
                fontFamily: 'Inter',
                fontWeight: 300,
                fontStyle: 'italic',
            },
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            fontSize: '12px',
            '& fieldset': {
                borderColor: '#AFAFAF',
            },
            '&:hover fieldset': {
                borderColor: '#AFAFAF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#AFAFAF',
            },
        },
        width: '30%',
        marginLeft: '18px',
    },
    SecondField: {
        '& .MuiInputBase-input': {
            padding: '6px 12px !important',
            color: '#fff',
            '&::placeholder': {
                fontFamily: 'Inter',
                fontWeight: 300,
                fontStyle: 'italic',
            },
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            fontSize: '12px',
            '& fieldset': {
                borderColor: '#AFAFAF',
            },
            '&:hover fieldset': {
                borderColor: '#AFAFAF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#AFAFAF',
            },
        },
        width: '50%',
        marginLeft: '18px',
    },
    ShortTextField: {
        '& .MuiInputBase-input': {
            padding: '6px 12px !important',
            color: '#fff',
            '&::placeholder': {
                fontFamily: 'Inter',
                fontWeight: 300,
                fontStyle: 'italic',
            },
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            fontSize: '12px',
            '& fieldset': {
                borderColor: '#AFAFAF',
            },
            '&:hover fieldset': {
                borderColor: '#AFAFAF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#AFAFAF',
            },
        },
        width: '40%',
        marginLeft: '22px',
    },
    separator: {
        borderBottom: '1px solid #FFFFFF',
        marginBottom: '35px',
        marginTop: '31px',
    },
    separatorVertical: {
        borderLeft: '1px solid #FFFFFF',
        height: '60px',
    },
    container: {
        borderRadius: '10px',
        width: '100%',
    },
    button: {
        color: '#fff',
        borderColor: '#fff',
    },
}));
interface EstablishmentData {
    logo: string;
    type: string;
    activity: string;
    address: string;
    company_name: string;
    tva_number: string;
}

interface SignUpData {
    f_name: string;
    l_name: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword?: string;
    lang: string;
    device_id: string;
    establishment: EstablishmentData;
}

interface SubscriptionOneProps {
    setSignUpData: React.Dispatch<React.SetStateAction<Partial<SignUpData>>>;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    setLogoImg: React.Dispatch<React.SetStateAction<File | null>>;
}

const SubscriptionTwo: React.FC<SubscriptionOneProps> = ({ setSignUpData, setStep, setLogoImg }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const formik = useFormik({
        initialValues: {
            logo: null as File | null, // Initialize as File | null
            type: 'type',
            activity: '',
            address: '',
            company_name: '',
            tva_number: '',
            phone: '',
        },

        validate: (values) => {
            console.log(values.logo)
            const errors: { [key: string]: string } = {};

            // Validate logo presence and file type
            if (!values.logo) {
                errors.logo = 'Logo Required';
            } else {
                const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
                if (!allowedTypes.includes(values.logo.type)) {
                    errors.logo = 'Only .jpg, .png, or .webp formats are allowed';
                }
            }

            // Validate type
            if (!values.type) {
                errors.type = 'Type selection Required';
            }

            // Validate activity
            if (!values.activity) {
                errors.activity = 'Activity Required';
            }

            // Validate address
            if (!values.address) {
                errors.address = 'Address Required';
            }

            // Validate company name
            if (!values.company_name) {
                errors.company_name = 'Company name Required';
            }

            // Validate TVA number
            if (!values.tva_number) {
                errors.tva_number = 'TVA number Required';
            }

            // Validate phone number (example validation for a 10-digit number)
            if (!values.phone) {
                errors.phone = 'Phone number Required';
            } else {
                const phoneRegex = /^[0-9]{10}$/; // Adjust regex based on your phone number format
                if (!phoneRegex.test(values.phone)) {
                    errors.phone = 'Invalid phone number';
                }
            }

            return errors;
        },

        onSubmit: (values) => {
            if (values.logo) {
                setLogoImg(values.logo);
            }
            setSignUpData((prev) => ({
                ...prev,
                phone: values.phone,
                establishment: {
                    logo: values.logo ? values.logo.name : '',
                    type: values.type,
                    activity: values.activity,
                    address: values.address,
                    company_name: values.company_name,
                    tva_number: values.tva_number,
                },
            }));

            setStep((prevStep) => prevStep + 1);
        },
    });

    return (
        <Box className={classes.container}>
            <Box className={classes.separator}></Box>
            <form onSubmit={formik.handleSubmit}>
                <Box display="flex" justifyContent="space-between" mb={'43px'}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: '15px',
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{ color: '#FFFFFF', fontFamily: 'Inter', fontSize: '12px' }}
                            >
                                {t('LogoEstablishment')}* :
                            </Typography>
                            <Button
                                variant="outlined"
                                component="label"
                                className={classes.button}
                                sx={{
                                    padding: '4px 22px 4px 22px',
                                    borderRadius: '45px ',
                                    border: '1px solid #2D2D2D',
                                    background: 'radial-gradient(#1A1A1A 100%, #0D0D0D 100%)',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        background: '#FFFFFF',
                                        color: '#000000',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                {t('Choose')}
                                <input
                                    type="file"
                                    hidden
                                    onChange={(event) => {
                                        const file = event.currentTarget.files?.[0] || null;
                                        formik.setFieldValue('logo', file);
                                    }}
                                    onBlur={formik.handleBlur}

                                />
                                {formik.touched.logo && formik.errors.logo && (
                                    <p style={{ color: '#FF4842', fontSize: '0.5rem', marginTop: '10px', fontWeight: '300', marginLeft: '10px' }}>
                                        {formik.errors.logo}
                                    </p>
                                )}
                            </Button>
                        </Box>
                        {formik.values.logo && (
                            <Typography
                                variant="body2"
                                sx={{
                                    color: '#FFFFFF',
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                }}
                            >
                                {formik.values.logo.name}
                            </Typography>
                        )}
                        <Typography
                            variant="body2"
                            color="#A3A3A3"
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: 300,
                                fontStyle: 'italic',
                                fontSize: '10px',
                            }}
                            mt={1}
                        >
                            {t('SelectAfile')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            gap: '9px',
                            position: 'relative',
                            right: '100px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{ color: '#FFFFFF', fontFamily: 'Inter', fontSize: '12px' }}
                            >
                                {t('EstablishmentActivity')}* :
                            </Typography>
                            <Select
                                sx={{
                                    border: 'none',
                                    width: '37% ',
                                    color: '#7B7B7B',
                                    fontStyle: 'italic',
                                    borderRadius: '10px',
                                    '& fieldset': {
                                        border: '1px solid #AFAFAF',
                                    },
                                    '& .MuiSelect-select': {
                                        color: '#7B7B7B',
                                        fontWeight: 300,
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: '#7B7B7B',
                                    },
                                    '& .MuiList-root': {
                                        color: '#7B7B7B',
                                    },
                                    '& .MuiMenuItem-root.Mui-selected:hover': {
                                        color: '#7B7B7B',
                                    },
                                    '& .css-1o0awoo-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1o0awoo-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1o0awoo-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                                        color: '#7B7B7B',
                                        fontFamily: 'Inter',
                                        fontWeight: 300,
                                        fontSize: '13px',
                                    },
                                }}
                                value={formik.values.activity}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="activity"
                                error={formik.touched.activity && Boolean(formik.errors.activity)}
                                className={classes.ShortTextField}
                            >
                                <MenuItem value="">Sélectionner</MenuItem>
                                <MenuItem value="Activity 1">Activity 1</MenuItem>
                                <MenuItem value="Activity 2">Activity 2</MenuItem>
                            </Select>
                            {formik.touched.activity && formik.errors.activity && (
                                <p style={{ color: '#FF4842', fontSize: '0.5rem', marginTop: '10px', fontWeight: '300', marginLeft: '10px' }}>
                                    {formik.errors.activity}
                                </p>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '150%',
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{ color: '#FFFFFF', fontFamily: 'Inter', fontSize: '12px' }}
                            >
                                {t('EstablishmentAddress')}* :
                            </Typography>
                            <TextField
                                id="address"
                                variant="outlined"
                                placeholder="12 Rue de la République 75018 Paris"
                                className={classes.SecondField}
                                inputProps={{ style: { color: '#fff' } }}
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="address"
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '15px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{ color: '#FFFFFF', fontFamily: 'Inter', fontSize: '12px' }}
                        >
                            {t('CompanyName')}* :
                        </Typography>
                        <TextField
                            id="company_name"
                            variant="outlined"
                            placeholder="Ex : EURL DUPOND MARTIN"
                            className={classes.LongTextField}
                            inputProps={{ style: { color: '#fff' } }}
                            value={formik.values.company_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="company_name"
                            error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                            helperText={formik.touched.company_name && formik.errors.company_name}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{ color: '#FFFFFF', fontFamily: 'Inter', fontSize: '12px' }}
                        >
                            TVA* :
                        </Typography>
                        <TextField
                            id="tva_number"
                            variant="outlined"
                            placeholder="FR21314134"
                            className={classes.LongTextField}
                            inputProps={{ style: { color: '#fff' } }}
                            value={formik.values.tva_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="tva_number"
                            error={formik.touched.tva_number && Boolean(formik.errors.tva_number)}
                            helperText={formik.touched.tva_number && formik.errors.tva_number}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{ color: '#FFFFFF', fontFamily: 'Inter', fontSize: '12px' }}
                        >
                            {t('Phone')}* :
                        </Typography>
                        <TextField
                            id="phone"
                            variant="outlined"
                            placeholder="0606060606"
                            className={classes.LongTextField}
                            inputProps={{ style: { color: '#fff' } }}
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="phone"
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />
                    </Box>
                </Box>

                <Box display="flex" justifyContent="center" mt={4}>
                    <Button
                        type="submit"
                        variant="outlined"
                        className={classes.button}
                        sx={{
                            padding: '4px 22px 4px 22px',
                            borderRadius: '45px ',
                            border: '1px solid #2D2D2D',
                            background: 'radial-gradient(#1A1A1A 100%, #0D0D0D 100%)',
                            boxShadow: 'none',
                            '&:hover': {
                                background: '#FFFFFF',
                                color: '#000000',
                                cursor: 'pointer',
                            },
                        }}
                    >
                        Submit
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default SubscriptionTwo;

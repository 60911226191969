import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Slice/authSlice';
import deviceReducer from './Slice/deviceSlice';
import selectedReducer from './Slice/selectedLeagueSlice';
import { fileUploadSlice } from './Slice/fileUploadSlice';
import languageReducer from './Slice/languageSlice'; // Import the language reducer

const store = configureStore({
  reducer: {
    auth: authReducer,
    device: deviceReducer,
    selected: selectedReducer,
    fileUpload: fileUploadSlice.reducer,
    language: languageReducer, // Add the language reducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import requests from "../Axios/Instance";

interface ProfileDataParams {
  user_id: string;
  f_name: string;
  l_name: string;
  phone: string;
  lang: string;
  establishment: establishmentData;
}
interface establishmentData {
  logo: string;
  type: string;
  activity: string;
  address: string;
  company_name: string;
  tva_number: string;
}
const UpdateProfileServices = {
  putprofileData: async (body: ProfileDataParams) => {
    return requests.put("/user/profile", body);
  },
};

export default UpdateProfileServices;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { customerSignIn, customerSignUp } from "../action";

// Define the specific shape of your user data
interface User {
  [key: string]: any;
}

interface AuthState {
  user: User | null;
  isLoggedIn: boolean;
  isLoading: boolean;
  status: string;
  errorMessage?: object;
}

const initialState: AuthState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")!)
    : null,
  isLoggedIn: false,
  isLoading: false,
  status: "Idle",
};

const authSlice = createSlice({
  name: "AuthLogin",
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<User>) {
      state.user = payload;
      state.isLoggedIn = true;
      localStorage.setItem("user", JSON.stringify(payload));
    },
    logOut(state) {
      state.user = null;
      state.isLoggedIn = false;
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerSignIn.pending, (state) => {
        state.isLoading = true;
        state.status = "Signing in...";
      })
      .addCase(customerSignIn.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = payload;
        state.status = "Signed in";
        localStorage.setItem("user", JSON.stringify(payload));
      })
      .addCase(customerSignIn.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.errorMessage = payload ? payload : {};
        state.status = "Sign in failed";
      })
      .addCase(customerSignUp.pending, (state) => {
        state.isLoading = true;
        state.status = "Signing up...";
      })
      .addCase(customerSignUp.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = payload;
        state.status = "Signed up";
        localStorage.setItem("user", JSON.stringify(payload));
      })
      .addCase(customerSignUp.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.errorMessage = payload ? payload : {};
        state.status = "Sign up failed";
      });
  },
});

export const { setUser, logOut } = authSlice.actions;
export default authSlice.reducer;

import requests from "../Axios/Instance";

const MatchesServices = {
  getMatchData: async (
    sportKey: string,
    leagueKey: string,
    startDate: string,
    endDate: string,
    timeZone: string
  ) => {
    return requests.get(
      `/matches?sport_key=${sportKey}&league_key=${leagueKey}&start_date=${startDate}&end_date=${endDate}&timezone=${timeZone}`
    );
  },
};

export default MatchesServices;

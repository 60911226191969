import requests from "../Axios/Instance";

const MainLeagueServices = {
  getMainLeagues: async (sportKey: string, pageNo: number, limit: number) => {
    return requests.get(
      `/leagues?sport_key=${sportKey}&page_no=${pageNo}&limit=${limit}`
    );
  },
};

export default MainLeagueServices;
